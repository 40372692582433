import React, { useEffect } from "react";
//import AboutBackground from "../Assets/home-background.png";
import Footer from "./Footer";
import "./About.css";
import More from "./More";
function About() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <More />

      {/* <div className="about-section-text-container">
          <h1 className="primary-heading"> </h1>

          <ul
            style={{
              textAlign: "left",
              maxWidth: "900px",
            }}
            className="primary-text"
          >
            <li>
              Our superfoods, like Foxnuts, are carefully harvested and
              processed to ensure maximum nutritional value and taste.
            </li>
            <li>
              We are proud to work with trusted suppliers and manufacturers to
              source our products.
            </li>
            <li>
              {" "}
              Our Spices are sourced from the finest farms and plantations, and
              our Fresh and Frozen Fruits and Vegetables are hand-selected for
              quality and flavor
            </li>
            <li>
              We work closely with our suppliers to ensure that our products are
              ethically sourced and produced.
            </li>
            <li>
              We believe that it is our responsibility to protect the
              environment and support the communities where our products are
              grown and harvested.
            </li>
            <li>
              We are confident that we can provide you with the highest quality
              products and exceptional service.
            </li>
          </ul>
        </div> */}

      <Footer />
    </div>
  );
}

export default About;

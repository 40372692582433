import React from "react";
import BannerBackground from "../Assets/home-banner.png";
import { Icon } from "@iconify/react";

function Work() {
  const workInfoData = [
    {
      icon: (
        <Icon
          icon="solar:medal-ribbons-star-bold-duotone"
          color="#FFD700"
          width="140"
          height="140"
        />
      ),
      title: "Premium Quality Products",
      text: "Eos World focuses on sourcing only the best quality products and trusted suppliers around the world",
    },
    {
      icon: (
        <Icon
          icon="fa6-solid:handshake"
          color="#000080"
          width="140"
          height="140"
        />
      ),

      title: "Strong Partnerships",
      text: "Eos World leverages its network of strong partnerships with farmers, suppliers, and distributors to ensure reliable supply and timely delivery of products",
    },
    {
      icon: (
        <Icon icon="mdi:environment" color="green" width="140" height="140" />
      ),
      title: " Sustainable and Ethical practices",
      text: "Eos World is committed to sustainable and ethical practices, such as fair trade, organic farming, and eco-friendly packaging.",
    },
    {
      icon: (
        <Icon
          icon="mdi:truck-delivery"
          color="#964B00"
          width="140"
          height="140"
        />
      ),
      title: "Reliable Service",
      text: "Eos World's timely and punctual delivery is a hallmark of reliable service, building trust and loyalty with customers.",
    },
  ];
  return (
    <div className="work-section-wrapper">
      <div className="work-section-top">
        <div className="banner-work">
          <img src={BannerBackground} alt="" />
        </div>
        <p className="primary-subheading"> </p>
        <h1 className="primary-heading">EosWorld</h1>
        <p className="primary-text">
          "Our company specializes in sourcing and exporting high-quality
          mangos, spices, and other products from around the world to customers
          globally."
        </p>
      </div>

      <div className="work-section-bottom">
        {workInfoData.map((data) => (
          <div className="work-section-info" key={data.title}>
            <div className="info-boxes-img-container">
              <div>{data.icon}</div>
            </div>
            <h2>{data.title}</h2>
            <p>{data.text}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Work;

import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";
import homeImg from "../Assets/Untitled-2.jpg";
function Home() {
  useEffect(() => {
    window.scroll(0, 0);
  });
  return (
    <div>
      <div
        className="home-container"
        style={{
          background: `url(${homeImg})`,
          backgroundSize: "100% 100%",
          backgroundAttachment: "scroll",
          height: "82vh",
        }}
      >
        <div className="home-banner-container">
          <div className="home-text-section">
            <div className="box">
              <h1> Pure Intent, Unaltered Will, Collaborating Global</h1>
            </div>
            <p className="home-heading">
              We Specialize in Exporting a wide range of High-Quality food
              products, including Spices, Fresh and Frozen Fruits and
              Vegetables, and Superfoods like Foxnuts.
            </p>
            <br />
            <Link
              className="secondary-button"
              to="/about"
              style={{ textDecoration: "none" }}
            >
              Our Story <FiArrowRight />{" "}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;

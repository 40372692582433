/* eslint-disable import/no-anonymous-default-export */

export default [
  {
    urls: "https://cdn.firstcry.com/education/2022/03/21114052/1265601385.jpg",
  },
  {
    urls: "https://images.everydayhealth.com/images/diet-nutrition/all-about-bananas-nutrition-facts-health-benefits-recipes-and-more-rm-722x406.jpg",
  },
  {
    urls: "https://images.herzindagi.info/image/2023/Feb/pomegranate-seeds-recipes-how-to-add-to-diet.jpg",
  },
  {
    urls: "https://www.almanac.com/sites/default/files/styles/or/public/image_nodes/green-grapes.jpg",
  },
  {
    urls: "https://www.richardjacksonsgarden.co.uk/wp-content/uploads/2019/03/AdobeStock_51675021_2200px-scaled-jpg.webp",
  },
  {
    urls: "https://www.news-medical.net/images/Article_Images/ImageForArticle_22726_16560822540037952.jpg",
  },
];

import React from "react";

function SliderProductContent({ sliderImage, activeIndex }) {
  return (
    <div>
      <section>
        {sliderImage.map((slide, index) => (
          <div
            key={index}
            className={index === activeIndex ? "slides active" : "inactive"}
          >
            <img className="slide-product-image" src={slide.urls} alt="" />
          </div>
        ))}
      </section>
    </div>
  );
}

export default SliderProductContent;

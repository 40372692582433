import React, { useEffect } from "react";
import Footer from "./Footer";

function Partner() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <br />
      <h1
        className="primary-heading"
        style={{
          color: "#ed3d24",
          fontSize: "3rem",
          fontFamily: "Great Vibes",
        }}
      >
        Welcome to our “Partner with us” page!
      </h1>
      <p
        className="primary-text"
        style={{
          textAlign: "center",
          margin: "auto",
          padding: "2%",
        }}
      >
        Eos World is an Indian firm that exports agricultural products and
        superfoods. We are constantly on the lookout for cooperation
        opportunities that will allow us to broaden our reach and provide our
        high-quality products to more clients throughout the world.
      </p>
      <div
        className="primary-text"
        style={{
          paddingTop: "8%",
          margin: "auto",
          textAlign: "left",
          maxWidth: "1000px",
          padding: "8% 2% 0% 2%",
        }}
      >
        <p className="primary-subheading">
          Benefits of Partnership with Eos World :{" "}
        </p>
        By collaborating with Eos World, you will gain access to our broad
        network of suppliers and customers in India, as well as our expertise in
        sourcing, processing, and exporting a variety of agricultural products
        and superfoods. We'll collaborate with you to develop tailored solutions
        that match your specific requirements and help you reach your business
        objectives.
        <br></br>
        <br></br>
        <p className="primary-subheading">Types of Partnerships : </p>
        We offer a variety of partnership opportunities, including:
        <br></br>
        <br></br>
        <p className="primary-subheading"></p>
        <b className="color-more">Distribution partnerships : </b> We work with
        distributors around the world to bring our products to local markets.
        <br></br>
        <br></br>
        <b className="color-more">Co-branding partnerships : </b>
        We can work together to develop and market products that combine our
        expertise and strengths.
        <br></br>
        <br></br>
        <b className="color-more">Ingredient partnerships : </b> We supply
        high-quality, premium ingredients for use in your products.
        <br></br>
        <br></br>
        <b className="color-more">How to Partner with Eos World : </b> If you’re
        interested in partnering with us, please fill out our contact form or
        send us an email at contact@eosworld.in Our partnership team will review
        your request and get back to you as soon as possible.
        <br></br>
        <br></br>
        <b className="color-more">Requirements : </b>
        We are seeking for partners who share our commitment to quality,
        sustainability, and social responsibility. If you want to become a
        distributor, you should have an established network in your local
        market. If you want to co-brand, you need have product development and
        marketing experience.
        <br></br>
        <br></br>
        <br></br>
        <b
          className="color-more"
          style={{
            fontSize: "2.5rem",
            fontWeight: "900",
            fontFamily: "Great Vibes",
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
          }}
        >
          Ready to partner with <br></br>
          Eos World?
        </b>
        <br></br>
        Get in touch today to explore partnership opportunities and take your
        business to the next level!
      </div>
      <Footer />
    </div>
  );
}

export default Partner;

/* eslint-disable import/no-anonymous-default-export */

export default [
  {
    urls: "https://i0.wp.com/post.healthline.com/wp-content/uploads/2021/05/makhana-1296x728-header.jpg",
  },
  {
    urls: "https://simmertoslimmer.com/wp-content/uploads/2020/06/Roasted-Makhana-Foxnuts-Lily-pops.jpg",
  },
  {
    urls: "https://images.healthshots.com/healthshots/en/uploads/2022/11/16182525/Makhana.jpg",
  },
];

/* eslint-disable import/no-anonymous-default-export */

//import corn from "../Assets/corn1.jpg";
//import peas from "../Assets/peas1.png";
export default [
  {
    urls: "https://tiimg.tistatic.com/fp/1/007/501/100-natural-and-pure-dehydrated-white-onion-flakes-668.jpg",
  },
  {
    urls: "https://maharajadehydration.com/wp-content/uploads/sites/381/2021/09/Red-onion-Kibbled-m20.jpg",
  },
  {
    urls: "https://5.imimg.com/data5/ANDROID/Default/2022/1/IU/JM/SN/96058065/product-jpeg-500x500.jpg",
  },
];

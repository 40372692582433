import React, { useEffect } from "react";
import { Link } from "react-router-dom";

function Introduction() {
  const intro = [
    {
      name: "https://i.ibb.co/BzSw5c1/fruits-vegetables-word-concept-47191088.jpg",
      link: "/fruits",
      text: "Fruits offer numerous health benefits due to their high nutrient content, including reducing the risk of chronic diseases and improving overall health and well-being.",
    },

    {
      name: "https://i.ibb.co/59rtjHf/spice.jpg",
      link: "/spices",
      text: "They are essential ingredients in various cuisines worldwide, each with its unique flavor and medicinal properties, ranging from anti-inflammatory to digestive.",
    },
    {
      name: "https://i.ibb.co/D9XgJgn/superfood-text-word-green-love-260nw-1406613272.jpg",
      link: "/super-food",
      text: "They are ideal snack for weight management and digestive health due to their high protein and fiber content, low-fat levels, and various vitamins and minerals.",
    },
    {
      name: "https://i.ibb.co/Tbvq6DF/Dehydrated-Food-1-1.png",
      link: "/dehydrated",
      text: "Drying food is one of the oldest and easi- est methods of food preservation. It is the process of removing moisture from a food product. Removing moisture makes them lighter.",
    },
  ];
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="intro-container">
        <div className="intro-text">
          <div className="intro-section-bottom">
            {intro.map((item) => (
              <div className="intro-section-info" key={item.name}>
                {/* <h1 style={{ fontSize: "30px", fontWeight: "500" }}>
                  {item.name}
                </h1> */}
                <img src={item.name} alt="" />

                <p style={{ fontWeight: "400" }}>{item.text}</p>
                <Link
                  className="secondary-button"
                  to={item.link}
                  style={{ textDecoration: "none", width: "12rem" }}
                >
                  Explore More
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Introduction;

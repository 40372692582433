import React, { useEffect } from "react";
import Footer from "./Footer";
import iso from "../Assets/iso.png";
import Spice from "../Assets/Space.png";
import Fda from "../Assets/FDA.jpg";
import Apeda from "../Assets/apeda.png";
function Certificate() {
  const certificate = [
    {
      name: "APEDA",
      imageUrl: Apeda,
      info: "It is an apex body established by the Government of India to promote the export of agricultural and processed food products. Our certification from APEDA ensures that our products meet the highest quality.",
    },
    {
      name: "ISO 22000",
      imageUrl: iso,
      info: "We are proud to hold the ISO 22000 certification, which is an international standard for food safety management systems. This shows commitment to maintaining the highest level of food safety and ensuring the quality.",
    },
    {
      name: "SPICE BOARD",
      imageUrl: Spice,
      info: "SPICE BOARD is an autonomous body under the Government of India that promotes the production and export of spices. Our certification from SPICE BOARD highlights our adherence to the stringent quality.",
    },
    {
      name: "FDA",
      imageUrl: Fda,
      info: "It is a regulatory agency in the United States responsible for protecting public health by ensuring the safety and efficacy of food and medical products. Our FDA certification confirms that our products meet the rigorous standards set by the FDA.",
    },
    {
      name: "USFDA",
      imageUrl: Fda,
      info: "Department of Health and Human Services that regulates food, drugs, and medical devices. Our USFDA certification indicates that our products have met the strict regulatory requirements of the USFDA, making them compliant with U.S. standards.",
    },
  ];
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <br />
      <h1
        className="primary-heading"
        style={{
          color: "#ed3d24",
          fontSize: "3rem",
          fontFamily: "Great Vibes",
        }}
      >
        Our Certifications
      </h1>
      <p
        className="primary-text"
        style={{
          textAlign: "center",
          margin: "auto",
          padding: "2%",
        }}
      >
        Certified for excellence, we uphold the highest standards to deliver
        trusted quality and exceptional value.
      </p>

      <div className="certificates-container">
        {certificate.map((certificate, index) => (
          <div key={index} className="certificate-card">
            <h2>{certificate.name}</h2>
            <img src={certificate.imageUrl} alt={certificate.name} />
            <p>{certificate.info}</p>
          </div>
        ))}
      </div>
      <br></br>
      <br></br>
      <Footer />
    </div>
  );
}

export default Certificate;

import "./App.css";
import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import $ from "jquery";
import "jquery-ui/ui/widgets/draggable";
import Home from "./Components/Home";
import About from "./Components/About";
import Work from "./Components/Work";
import Contact from "./Components/Contact";
import Footer from "./Components/Footer";
import Navbar from "./Components/Navbar";
import Slider from "./Components/Slider";
import Spices from "./Components/Spices";
import SpicesProduct from "./Components/Spices_Product";
import FruitsProduct from "./Components/Fruits_Products";
import SuperFoodProduct from "./Components/SuperFood_Products";
import Frozen from "./Components/Frozen";
import Fruits from "./Components/Fruits";
import Vegiee from "./Components/Vegetables";
import VegetablesProduct from "./Components/Vegetables_Product";
import Intro from "./Components/Introduction";
import Dehydrated from "./Components/Dehydrated_Products";
import More from "./Components/More";
import FrozenFruit from "./Components/FrozenFruits";
import Partner from "./Components/Partner";
import Certificate from "./Components/Certificate";
import Rice from "./Components/Rice";
import Youtube from "./Components/Youtube";
function App() {
  useEffect(() => {
    window.scrollTo(0, 0);
    $(".whatsapp_float").draggable();
  }, []);
  return (
    <Router>
      <div className="App">
        <div id="google_translate_element"></div>
        <Navbar />

        <div className="whatsapp_float">
          <a
            href="https://wa.me/919028986499"
            target="_blank"
            alt="link"
            rel="noreferrer"
          >
            <img
              src="https://i.ibb.co/7QFcnbP/unnamed-1.png"
              width={50}
              alt="unnamed-1"
              border="0"
            />
          </a>
        </div>

        <Routes>
          <Route
            path="/"
            element={
              <>
                <Home />

                <Slider />
                <Intro />
                <Work />
                <Youtube />
                <Footer />
              </>
            }
          />
          <Route path="/about" element={<About />} />
          <Route
            path="/products"
            element={
              <>
                <Fruits
                  img="https://i.ibb.co/BzSw5c1/fruits-vegetables-word-concept-47191088.jpg"
                  content="Welcome to the world of pure Foods . A place for 100% vegetarian and truly state of art processing house at Ahmednager, Maharashtra in India."
                  link="/fruits"
                />

                <Spices
                  img="https://i.ibb.co/59rtjHf/spice.jpg"
                  content="Chilly pepper is the fruit of the plants from the genus Capsicum ,members of the Solonaceae family and the chilly powder is obtained by grinding red chill to the required granulation "
                  link="/spices"
                />
                <Frozen
                  img="https://i.ibb.co/D9XgJgn/superfood-text-word-green-love-260nw-1406613272.jpg"
                  content="It is a good snack as well as a good source of calcium. 60mg in every 100 g. It is a is recommended for those with digestion issues because its very high fibre content is proven to be good for body metabolism."
                  link="/super-food"
                />

                <Vegiee
                  img="https://i.ibb.co/Tbvq6DF/Dehydrated-Food-1-1.png"
                  content="Eos World has introduced fresh varieties of dehydrated white onions,which include in the form of, flakes/kibbles, granules, minced, chopped down and powder."
                  link="/dehydrated"
                />
              </>
            }
          ></Route>
          <Route path="/contact" element={<Contact />} />
          <Route path="/spices" element={<SpicesProduct />} />
          <Route path="/fruits" element={<FruitsProduct />} />
          <Route path="/super-food" element={<SuperFoodProduct />} />
          <Route path="/vegetables" element={<VegetablesProduct />} />
          <Route path="/learn-more" element={<More />}></Route>
          <Route path="/dehydrated" element={<Dehydrated />}></Route>
          <Route path="/frozen-fruit" element={<FrozenFruit />}></Route>
          <Route path="/partner" element={<Partner />}></Route>
          <Route path="/rice" element={<Rice />}></Route>
          <Route path="/certificate" element={<Certificate />}></Route>
        </Routes>
      </div>
    </Router>
  );
}

export default App;

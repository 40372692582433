import React, { useEffect, useRef, useState } from "react";
import video1 from "../Assets/Spices_.mp4";
import { Icon } from "@iconify/react";
import img1 from "../Assets/red.jpg";
import img2 from "../Assets/spices.jpg";
import { useLocation } from "react-router-dom";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";

function Spices_Product() {
  const spices = [
    {
      name: "Teja Chillies",
      img: "https://i.ibb.co/GWC9Nhj/teja-chilly.jpg",
      text: "These are long and thin with a sharp pointed tip, and the hottest varieties of chillies in India, helps in making spicy Indian dishes like biryanis and curries. Eos World exports Teja Chillies  generally having a Scoville Heat Unit from 75,000 to 100,000 SHU in dried whole and powdered forms.",
    },
    {
      name: "S334 Chillies",
      img: "https://www.bstspices.com/assets/images/s334-chilli-with-stem-532x355.png",
      text: "These are medium-hot chillies that are similar to Teja chillies in appearance but are slightly milder in heat, mostly spice blends and for adding flavor and heat to dishes. Eos World exports S334 Chillies having Scoville Heat Unit from 20,000 to 30,000 SHU in dried whole and powdered forms.",
    },
    {
      name: "S273 Chillies",
      img: "https://om-dashboards-dev.s3.ap-south-1.amazonaws.com/products/f7592276-6516-4073-9ed2-1e7b40759ae0/1515577667993.jpg",
      text: "These are mild to medium-hot chillies that are popular for their bright red color and fruity flavor. They are commonly used in making pickles, chutneys, and marinades. Eos World exports S273 Chillies having Scoville Heat Unit from 10,000 to 15,000 SHU in dried whole and powdered forms.",
    },
    {
      name: "Byadgi Chillies",
      img: "https://i.ibb.co/QJ5B6v6/Byadgi-Chillies.jpg",
      text: "These are famous for their deep red color and mild heat, and They are often used in making Indian curries and spice blends or some non-veg meals. Eos World exports Byadgi Chillies having Scoville Heat Unit from 5,000 to 10,000 SHU in dried whole and powdered forms.",
    },
    {
      name: "King Chilli or Ghost Chilli ",
      img: "https://i.ibb.co/X2CX9sD/King-Chilli-or-Ghost-Chilli.webp",
      text: "Also known as the Bhut Jolokia, this is one of the hottest chillies in the world and is primarily used for making hot sauces and spice blends. Eos World exports King Chillies or Ghost Chillies that boast Scoville Heat Unit of 1,000,000 SHU",
    },
    {
      name: "Bird's Eye Chilli",
      img: "https://i.ibb.co/Y3STN2W/chilly.jpg",
      text: "Bird's Eye Chillies are one of the most popular varieties of dried red chillies that are exported from India. Eos World exports Bird's Eye Chillies having Scoville Heat Unit from 50,000 to 100,000 SHU in dried whole and powdered forms. I",
    },
    {
      name: "Dried Red Chillies Export packaging",
      img: "https://i.ibb.co/SvnXkMJ/Whats-App-Image-2023-04-05-at-07-51-02.jpg",
      text: "15kg / 25kg / 50kg PP bags or Gunny Bags",
    },
    {
      name: "Container loading capacity",
      img: "https://i.ibb.co/YjJvxGf/container.jpg",
      text: "20' FCL: 6.5MT, 40' FCL: 14MT",
    },
  ];
  const turmeric = [
    {
      name: "Details",
      text: "Type: Finger/Buld | HS Code:091030 | Purity: 99.5% | Extraneous Foreign Matter (% by Weight): 0.50% Max | Moisture: 12% Max | Salmonella:Absent / 25 Gms | Total Ash: 8% Max  |  Acid Insoluble Ash: 2% Max | Curcumin: 1% to 9%",
    },
    {
      name: "Export packaging",
      text: "15kg / 25kg / 50kg PP bags",
    },
    { name: "Container loading capacity", text: "20' FCL: 20MT 40' FCL: 27MT" },
  ];
  const ginger = [
    {
      name: "Details",
      text: "HS Code: 091012 | CAS:  23513-14-6 | Chemical formular: C17H26O4 | Molecular weight: 294.39 | Appearance: Brown Yellow Powder | Oder: Characteristic | Taste: Characteristic | Particle Size: 100% through 80 Mesh | Loss on Drying:< 5.0% | Heavy Metals: < 20ppm ",
    },
    {
      name: "Export packaging",
      text: "Export packaging",
    },
    {
      name: "Container loading capacity",
      text: "20' FCL: 14 MT | 40' FCL: 25 MT",
    },
  ];
  const sesame = [
    {
      name: "Details",
      text: "Purity: Up to 99.98% | Moisture: 4% to 5% Max | Oil: 51% to 60% | FFA : 0.2% to 1.25%",
    },
    {
      name: "Export packaging",
      text: "30kg / 50kg PP bags or Kraft paper bags",
    },
    {
      name: "Container loading capacity",
      text: "20' FCL: 24 MT | 40' FCL: 27 MT",
    },
  ];
  const cumin = [
    {
      name: "Details",
      text: "HS Code: PURITY : 96% to 99.95% | ADMIXTURE: 0.05% to 4% | CLEANING: Machine Cleaned | MOISTURE: 8%",
    },
    {
      name: "Export packaging",
      text: "15kg / 25kg / 50kg PP bags",
    },
    {
      name: "Container loading capacity",
      text: "20' FCL: 24 MT | 40' FCL: 27 MT",
    },
  ];
  const redref = useRef(false);
  const turmericref = useRef(false);
  const gingerref = useRef(false);
  const cuminref = useRef(false);
  const seasameref = useRef(false);
  const cardamomref = useRef(false);
  const location = useLocation();
  const [inputValue, setInputValue] = useState("");
  const h1Ref1 = useRef(null);
  const h1Ref2 = useRef(null);
  const h1Ref3 = useRef(null);
  const h1Ref4 = useRef(null);
  const h1Ref5 = useRef(null);
  const h1Ref6 = useRef(null);
  useEffect(() => {
    window.scrollTo(0, 0);
    if (location.hash === "#scrollred") {
      redref.current.scrollIntoView({ behaviour: "smooth" });
    }
    if (location.hash === "#scrollturmeric") {
      turmericref.current.scrollIntoView({ behaviour: "smooth" });
    }
    if (location.hash === "#scrollginger") {
      gingerref.current.scrollIntoView({ behaviour: "smooth" });
    }
    if (location.hash === "#scrollcumin") {
      cuminref.current.scrollIntoView({ behaviour: "smooth" });
    }
    if (location.hash === "#scrollseasame") {
      seasameref.current.scrollIntoView({ behaviour: "smooth" });
    }
    if (location.hash === "#scrollcardamom") {
      cardamomref.current.scrollIntoView({ behaviour: "smooth" });
    }
  }, [location]);
  //Search
  function scrollToMatchingH1Tag() {
    const h1Tags = [
      h1Ref1.current,
      h1Ref2.current,
      h1Ref3.current,
      h1Ref4.current,
      h1Ref5.current,
      h1Ref6.current,
    ];
    h1Tags.forEach((h1Tag) => {
      if (h1Tag.textContent.toLowerCase().includes(inputValue.toLowerCase())) {
        h1Tag.scrollIntoView({ behavior: "smooth" });
      }
    });
  }

  useEffect((e) => {
    const handleKeyPress = (e) => {
      if (e.key === "Enter") {
        scrollToMatchingH1Tag();
      }
    };
    document.addEventListener("keypress", handleKeyPress);
    //scrollToMatchingH1Tag();
    return () => {
      document.removeEventListener("keypress", handleKeyPress);
    };
  });
  return (
    <div>
      <div className="work-section-wrapper">
        <div className="work-section-top" id="scrollred" ref={redref}>
          <p className="primary-subheading">
            <img src="https://i.ibb.co/59rtjHf/spice.jpg" alt="" />
          </p>
          <div className="search-box" style={{ marginBottom: "5%" }}>
            <input
              type="text"
              placeholder="Search..."
              value={inputValue}
              onChange={(event) => setInputValue(event.target.value)}
              // onKeyPress={(event) => handleKeyPress(event)}
            />
            <Icon
              icon="ic:outline-search"
              className="search-icon"
              color="#fff"
            />
            <div className="search"></div>
          </div>
          <div className="work-video">
            <video
              controls={false}
              width="100%"
              loop
              muted
              autoPlay
              playsInline
              style={{ pointerEvents: "none" }}
            >
              <source src={video1} type="video/mp4" />
              Sorry your browser not support video
            </video>
          </div>
          <p className="primary-text">
            A spice is a seed, fruit, root, bark, or other plant substance
            primarily used for flavoring or coloring food. Spices are
            distinguished from herbs, which are the leaves, flowers, or stems of
            plants used for flavoring or as a garnish.
          </p>
        </div>

        <div className="work-section-top">
          <p className="primary-subheading"></p>
          <h1 className="primary-heading" ref={h1Ref1}>
            Dried Red chilli
          </h1>
          <div className="fruit-images" style={{ marginTop: "2%" }}>
            <div className="fruit1">
              <div className="one ">
                <img src={img1} alt="chilly" border="0" />
              </div>
              <div className="one">
                <img src={img2} alt="ezgif-4-dd36c18c87" border="0" />
              </div>
            </div>
          </div>
          <p className="primary-text">
            A part of the berry fruit family and is central to many kitchens for
            its zing.Chilli is the dried ripe fruit of the genus Capsicum.
            Capsicum annuum is an annual sub –shrub, the flowers of which are
            borne singly and fruits usually pendent.
          </p>
        </div>

        <div className="fruit-container">
          <div className="intro-text">
            <div className="fruit-section-bottom">
              {spices.map((item) => (
                <div
                  className="fruit-section-info"
                  key={item.name}
                  style={{ backgroundImage: `url(${item.img})` }}
                >
                  <h1>{item.name}</h1>

                  <p>{item.text}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="see-more-content">
          <small>
            <u>slide here</u>
          </small>
          <KeyboardDoubleArrowRightIcon />
        </div>
        <div
          className="scroll"
          ref={turmericref}
          id="scrollturmeric"
          style={{ position: "absolute" }}
        ></div>
        <hr
          style={{
            borderBottom: "1px solid #000",
            width: "100%",
            marginBottom: "5%",
          }}
        ></hr>

        <div className="work-section-top">
          <p className="primary-subheading"></p>
          <h1 className="primary-heading" ref={h1Ref2}>
            Turmeric
          </h1>
          <div className="fruit-images" style={{ marginTop: "2%" }}>
            <div className="fruit1">
              <div className="one ">
                <img
                  src="https://i.ibb.co/jk7vJ8x/Turmeric-Spices-Type-Gs-Exports.jpg"
                  alt="Turmeric-Spices-Type-Gs-Exports"
                  border="0"
                />
              </div>
              <div className="one">
                <img
                  src="https://i.ibb.co/tXnnwkN/turmeric-root-and-powder.jpg"
                  alt="ezgif-4-dd36c18c87"
                  border="0"
                />
              </div>
            </div>
          </div>
          <p className="primary-text">
            India is one of the largest producers and exporters of turmeric in
            the world, and there are several different types of turmeric that
            are grown and exported from different regions of the country. Here
            are some of the most common types of turmeric and their curcumin
            percentage ranging from 1-3%, 3-6%, 6-9%, 9-12% depending on the
            Indian geographies, temperature and other climatic factors.
            <br></br>
            At Eos World we take pride in exporting high-quality turmeric and
            ensuring that our customers receive the best possible service
          </p>
        </div>

        <div className="fruit-container">
          <div className="intro-text">
            <div className="fruit-section-bottom">
              {turmeric.map((item) => (
                <div className="fruit-section-info" key={item.name}>
                  <h1 style={{ fontSize: "1.5rem", fontWeight: "500" }}>
                    {item.name}
                  </h1>
                  <p style={{ fontWeight: "400" }}>{item.text}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="see-more-content">
          <small>
            <u>slide here</u>
          </small>
          <KeyboardDoubleArrowRightIcon />
        </div>
        <hr
          style={{
            borderBottom: "1px solid #000",
            width: "100%",
            marginBottom: "5%",
          }}
        ></hr>
        <div
          className="scroll"
          ref={gingerref}
          id="scrollginger"
          style={{ position: "absolute" }}
        ></div>
        <div className="work-section-top">
          <p className="primary-subheading"></p>
          <h1 className="primary-heading" ref={h1Ref3}>
            Ginger
          </h1>
          <div className="fruit-images" style={{ marginTop: "2%" }}>
            <div className="fruit1">
              <div className="one ">
                <img
                  src="https://i.ibb.co/XyQjWvw/Carrot-Ginger-Soup-009.jpg"
                  alt="ganesh-pomegranate-1557830044-4904571"
                  border="0"
                />
              </div>
              <div className="one">
                <img
                  src="https://i.ibb.co/RpfBz8q/Ginger-Spices-Type-Gs-Exports.jpg"
                  alt="ezgif-4-dd36c18c87"
                  border="0"
                />
              </div>
            </div>
          </div>
          <p className="primary-text">
            Ginger is herb that grows mainly in Asia is used as a spice in
            cooking. It is also known for its therapeutic qualities. It is an
            underground stem (rhizome) that can be used fresh, powdered, dried,
            or in the slice dried form.
          </p>
        </div>

        <div className="fruit-container">
          <div className="intro-text">
            <div className="fruit-section-bottom">
              {ginger.map((item) => (
                <div className="fruit-section-info" key={item.name}>
                  <h1 style={{ fontSize: "1.5rem", fontWeight: "500" }}>
                    {item.name}
                  </h1>
                  <p style={{ fontWeight: "400" }}>{item.text}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="see-more-content">
          <small>
            <u>slide here</u>
          </small>
          <KeyboardDoubleArrowRightIcon />
        </div>
        <hr
          style={{
            borderBottom: "1px solid #000",
            width: "100%",
            marginBottom: "5%",
          }}
        ></hr>
        <div
          className="scroll"
          ref={seasameref}
          id="scrollseasame"
          style={{ position: "absolute" }}
        ></div>
        <div className="work-section-top">
          <p className="primary-subheading"></p>
          <h1 className="primary-heading" ref={h1Ref4}>
            Sesame Seeds
          </h1>
          <div className="fruit-images" style={{ marginTop: "2%" }}>
            <div className="fruit1">
              <div className="one ">
                <img
                  src="https://i.ibb.co/2PDfRBw/156423.jpg"
                  alt="ganesh-pomegranate-1557830044-4904571"
                  border="0"
                />
              </div>
              <div className="one">
                <img
                  src="https://i.ibb.co/JsfFdFr/sesame-seeds.jpg"
                  alt="ezgif-4-dd36c18c87"
                  border="0"
                />
              </div>
            </div>
          </div>
          <p className="primary-text">
            India is one of the top producers and exporters of sesame seeds in
            the world, accounting for approximately 26% of the global
            production. At Eos World, we export Natural seasame seeds, Hulled
            seasame seeds and white seasame seeds. Eos World's Indian sesame
            seeds are known for their high quality, taste, and aroma.
          </p>
        </div>

        <div className="fruit-container">
          <div className="intro-text">
            <div className="fruit-section-bottom">
              {sesame.map((item) => (
                <div className="fruit-section-info" key={item.name}>
                  <h1 style={{ fontSize: "1.5rem", fontWeight: "500" }}>
                    {item.name}
                  </h1>
                  <p style={{ fontWeight: "400" }}>{item.text}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="see-more-content">
          <small>
            <u>slide here</u>
          </small>
          <KeyboardDoubleArrowRightIcon />
        </div>
        <hr
          style={{
            borderBottom: "1px solid #000",
            width: "100%",
            marginBottom: "5%",
          }}
        ></hr>
        <div
          className="scroll"
          ref={cardamomref}
          id="scrollcardamom"
          style={{ position: "absolute" }}
        ></div>
        <div className="work-section-top">
          <p className="primary-subheading"></p>
          <h1 className="primary-heading" ref={h1Ref5}>
            Cardamom
          </h1>
          <div className="fruit-images" style={{ marginTop: "2%" }}>
            <div className="fruit1">
              <div className="one ">
                <img
                  src="https://i.ibb.co/XpVrHDS/carradamon.jpg"
                  alt="ganesh-pomegranate-1557830044-4904571"
                  border="0"
                />
              </div>
              <div className="one">
                <img
                  src="https://i.ibb.co/q00vMVr/Cardamom-Spices-Type-Gs-Exports.jpg"
                  alt="ezgif-4-dd36c18c87"
                  border="0"
                />
              </div>
            </div>
          </div>
          <p className="primary-text">
            Cardamom is a spice with a strong, sweet aroma and flavor. It is
            commonly used in Indian, Middle Eastern, and Scandinavian cuisine.
            Cardamom is rich in antioxidants and may have anti-inflammatory
            properties. It is also used in traditional medicine to treat
            digestive issues and bad breath.
          </p>
        </div>

        <hr
          style={{
            borderBottom: "1px solid #000",
            width: "100%",
            marginBottom: "5%",
          }}
        ></hr>
        <div
          className="scroll"
          ref={cuminref}
          id="scrollcumin"
          style={{ position: "absolute" }}
        ></div>
        <div className="work-section-top">
          <p className="primary-subheading"></p>
          <h1 className="primary-heading" ref={h1Ref6}>
            Cumin Seeds
          </h1>
          <div className="fruit-images" style={{ marginTop: "2%" }}>
            <div className="fruit1">
              <div className="one ">
                <img
                  src="https://i.ibb.co/S6MC154/Cumin-Spices-Type-Gs-Exports.jpg"
                  alt="ganesh-pomegranate-1557830044-4904571"
                  border="0"
                />
              </div>
              <div className="one">
                <img
                  src="https://i.ibb.co/1mPymnq/Cumin-Powder-Benefits-1-jpg.webp"
                  alt="ezgif-4-dd36c18c87"
                  border="0"
                />
              </div>
            </div>
          </div>

          <p className="primary-text">
            India is one of the largest producers and exporters of cumin seeds
            in the world, accounting for around 70% of the global production.
            Eos World's cumin seeds are popular in the global market due to
            their high quality and distinct aroma and flavor
          </p>
        </div>

        <div className="fruit-container">
          <div className="intro-text">
            <div className="fruit-section-bottom">
              {cumin.map((item) => (
                <div className="fruit-section-info" key={item.name}>
                  <h1 style={{ fontSize: "1.5rem", fontWeight: "500" }}>
                    {item.name}
                  </h1>
                  <p style={{ fontWeight: "400" }}>{item.text}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="see-more-content">
          <small>
            <u>slide here</u>
          </small>
          <KeyboardDoubleArrowRightIcon />
        </div>
      </div>
    </div>
  );
}

export default Spices_Product;

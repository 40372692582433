/* eslint-disable import/no-anonymous-default-export */
import First from "../Assets/spices1.jpg";
import Second from "../Assets/foxnuts-5.jpg";
import Third from "../Assets/mango.jpg";
import Fourth from "../Assets/pomegranate_.jpg";
import Fifth from "../Assets/gr.jpg";
import Sixth from "../Assets/rice_.jpg";

export default [
  {
    urls: First,
    text: "Spices - The secret to global cuisine's aromatic delight.",
  },
  {
    urls: Second,
    text: "Foxnuts - Crunchy and healthy, a perfect snack anytime.",
  },
  {
    urls: Third,
    text: "Mangoes - The king of fruits, sweet and juicy with every bite.",
  },
  {
    urls: Fourth,
    text: "Pomegranate - Bursting with antioxidants, a powerhouse fruit.",
  },
  {
    urls: Fifth,
    text: "Grapes - From vine to wine, a versatile fruit for every occasion.",
  },
  {
    urls: Sixth,
    text: "Indian rice - Basmati, a fragrant grain fit for royalty.",
  },
];

import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { Icon } from "@iconify/react";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
function Dehydrated_Products() {
  const location = useLocation();
  const onionref = useRef();
  const garlicref = useRef();
  const spicesref = useRef();
  const driedref = useRef();
  const [inputValue, setInputValue] = useState("");
  const h1Ref1 = useRef(null);
  const h1Ref2 = useRef(null);
  const h1Ref3 = useRef(null);
  const h1Ref4 = useRef(null);
  const h1Ref5 = useRef(null);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (location.hash === "#scrollonion") {
      onionref.current.scrollIntoView({ behaviour: "smooth" });
    }
    if (location.hash === "#scrollgarlic") {
      garlicref.current.scrollIntoView({ behaviour: "smooth" });
    }
    if (location.hash === "#scrollspices") {
      spicesref.current.scrollIntoView({ behaviour: "smooth" });
    }
    if (location.hash === "#scrolldried") {
      driedref.current.scrollIntoView({ behaviour: "smooth" });
    }
  }, [location]);
  const whiteonion = [
    {
      img: "https://5.imimg.com/data5/LN/WW/MO/SELLER-89423136/dehydrated-white-onion-flakes-500x500.jpeg",
      name: "Flakes",
      text: "White onion can be prepared in different sizes to suit various culinary requirements. When sliced into flakes, the size typically ranges from 8-20mm",
    },
    {
      img: "https://5.imimg.com/data5/KM/RC/MY-13519091/dehydrated-white-onion-chopped-500x500.jpg",
      name: "Chopped",
      text: " Chopped white onion, with a size of 3-5mm, is commonly used in salads, sauces, and marinades, adding a distinct flavor and texture.",
    },
    {
      img: "https://2.wlimg.com/product_images/bc-full/2018/8/4257404/dehydrated-white-onion-minced-1534759095-4210828.jpeg",
      name: "Minced",
      text: "For a more subtle onion flavor, minced white onion, with a size of 1-3mm, is a popular choice for use in dressings, dips, and spreads.",
    },
    {
      img: "https://tiimg.tistatic.com/fp/2/004/999/dehydrated-white-onion-granules-415.jpg",
      name: "Granules",
      text: "Granulated white onion, with a size of 0-2mm, can be used in spice blends and rubs, imparting a more concentrated flavor",
    },
    {
      img: "https://5.imimg.com/data5/YQ/LT/WK/SELLER-94661056/dehydrated-white-onion-powder-500x500.jpg",
      name: "Powder",
      text: "White onion powder, with a fine mesh size of 80 to 100, is perfect for adding a mild onion.It is commonly used in seasoning blends, dry rubs, and marinades.",
    },
  ];
  const redonion = [
    {
      img: "https://5.imimg.com/data5/SELLER/Default/2020/9/WW/GU/RQ/3181846/red-onion-flakes-500x500.png",
      name: "Flakes",
      text: "Red onion can be prepared in different sizes to suit various culinary requirements. When sliced into flakes, the size typically ranges from 8-20mm",
    },
    {
      img: "https://wholefully.com/wp-content/uploads/2021/02/diced-chopped-sliced-onions.jpg",
      name: "Chopped",
      text: " Chopped Red onion, with a size of 3-5mm, is commonly used in salads, sauces, and marinades, adding a distinct flavor and texture.",
    },
    {
      img: "https://5.imimg.com/data5/TA/GS/MY-10834263/dehydrated-red-onion-minced-500x500.jpeg",
      name: "Minced",
      text: "For a more subtle onion flavor, minced white onion, with a size of 1-3mm, is a popular choice for use in dressings, dips, and spreads.",
    },
    {
      img: "https://5.imimg.com/data5/SELLER/Default/2022/2/UF/UP/SP/125175357/dehydrated-red-onion-granules-500x500.JPG",
      name: "Granules",
      text: "Granulated red onion, with a size of 0-2mm, can be used in spice blends and rubs, imparting a more concentrated flavor",
    },
    {
      img: "https://5.imimg.com/data5/KP/SK/RY/SELLER-94661056/dehydrated-red-onion-powder-500x500.jpg",
      name: "Powder",
      text: "Red onion powder, with a fine mesh size of 80 to 100, is perfect for adding a mild onion.It is commonly used in seasoning blends, dry rubs, and marinades.",
    },
  ];
  const pinkonion = [
    {
      img: "https://cpimg.tistatic.com/06940419/b/5/Dehydrated-Pink-Onion-Flakes-Kibbled.png",
      name: "Flakes",
      text: "Pink onion can be prepared in different sizes to suit various culinary requirements. When sliced into flakes, the size typically ranges from 8-20mm",
    },
    {
      img: "https://5.imimg.com/data5/SELLER/Default/2021/9/TN/JM/HM/132588764/dehydrated-pink-onion-chopped-500x500.jpg",
      name: "Chopped",
      text: " Chopped Pink onion, with a size of 3-5mm, is commonly used in salads, sauces, and marinades, adding a distinct flavor and texture.",
    },
    {
      img: "https://5.imimg.com/data5/DP/TR/PM/SELLER-46244442/dehydrated-pink-onion-minced-500x500.jpg",
      name: "Minced",
      text: "For a more subtle onion flavor, minced white onion, with a size of 1-3mm, is a popular choice for use in dressings, dips, and spreads.",
    },
    {
      img: "https://5.imimg.com/data5/ANDROID/Default/2022/6/HY/UV/DT/136384381/product-jpeg-500x500.jpg",
      name: "Granules",
      text: "Granulated pink onion, with a size of 0-2mm, can be used in spice blends and rubs, imparting a more concentrated flavor",
    },
    {
      img: "https://5.imimg.com/data5/SELLER/Default/2022/2/GG/EZ/IJ/125175357/dehydrated-pink-onion-powder-1000x1000.JPG",
      name: "Powder",
      text: "Pink onion powder, with a fine mesh size of 80 to 100, is perfect for adding a mild onion.It is commonly used in seasoning blends, dry rubs, and marinades.",
    },
  ];
  const garlic = [
    {
      img: "https://5.imimg.com/data5/RO/RG/MY-23228463/dehydrated-garlic-flakes-250x250.jpg",
      name: "Flakes",
      text: "Garlic cloves, when sliced into flakes, usually measure between 8-20mm, making it ideal for use in stews, soups, and marinades.",
    },
    {
      img: "https://4.imimg.com/data4/DL/UH/MY-5490390/10-1000x1000.png",
      name: "Chopped",
      text: "Garlic has a unique taste and aroma, and the various forms of garlic available make it an indispensable ingredient in many recipes of size 3-5mm.",
    },
    {
      img: "https://www.jessicagavin.com/wp-content/uploads/2019/09/how-to-mince-garlic-3-1200.jpg",
      name: "Minced",
      text: "Minced garlic, with a size of 1-3mm, is a popular choice for dressings, spreads, and marinades, where a more subtle garlic flavor is required.",
    },
    {
      img: "https://img2.exportersindia.com/product_images/bc-full/2021/9/7594065/garlic-granules-1631339669-5986233.jpeg",
      name: "Granules",
      text: "Granulated garlic, with a size of 0-2mm, is an excellent choice for spice blends, seasoning mixes, and rubs, giving a more concentrated flavor to the dishes.",
    },
    {
      img: "https://5.imimg.com/data5/MM/GW/MY-3507680/garlic-powder-500x500.jpg",
      name: "Powder",
      text: "Garlic powder, with a fine mesh size of 80 to 100, is ideal for adding a mild garlic.It is commonly used in sauces, marinades, and dry rubs.",
    },
  ];
  //search
  function scrollToMatchingH1Tag() {
    const h1Tags = [
      h1Ref1.current,
      h1Ref2.current,
      h1Ref3.current,
      h1Ref4.current,
      h1Ref5.current,
    ];
    h1Tags.forEach((h1Tag) => {
      if (h1Tag.textContent.toLowerCase().includes(inputValue.toLowerCase())) {
        h1Tag.scrollIntoView({ behavior: "smooth" });
      }
    });
  }

  useEffect((e) => {
    const handleKeyPress = (e) => {
      if (e.key === "Enter") {
        scrollToMatchingH1Tag();
      }
    };
    document.addEventListener("keypress", handleKeyPress);
    //scrollToMatchingH1Tag();
    return () => {
      document.removeEventListener("keypress", handleKeyPress);
    };
  });
  return (
    <div>
      <div className="work-section-wrapper">
        <div className="work-section-top" id="scrollonion" ref={onionref}>
          <p className="primary-subheading">
            <img
              src="https://i.ibb.co/Tbvq6DF/Dehydrated-Food-1-1.png"
              alt=""
            />
          </p>
          <div className="search-box">
            <input
              type="text"
              placeholder="Search..."
              value={inputValue}
              onChange={(event) => setInputValue(event.target.value)}
              // onKeyPress={(event) => handleKeyPress(event)}
            />
            <Icon
              icon="ic:outline-search"
              className="search-icon"
              color="#fff"
            />
          </div>
          <h1 className="primary-heading" ref={h1Ref1}>
            {" "}
            Dehydrated white Onion
          </h1>
          <div className="fruit-images">
            <div className="fruit1">
              <img
                src="https://tiimg.tistatic.com/fp/1/007/501/100-natural-and-pure-dehydrated-white-onion-flakes-668.jpg"
                alt="is-corn-grain-1544222258"
                border="0"
              />
            </div>
          </div>
          <p>
            Eos World has introduced fresh varieties of dehydrated white onions,
            which include in the form of, flakes/kibbles, granules, minced,
            chopped down and powder. The dehydrated white onions are used for
            various purposes and are enriched with a flavorsome taste and
            fragrance. The thorough processing and the hygienic packing makes
            these dehydrated white onions easily accessible to the customers.
          </p>
        </div>

        <div className="fruit-container">
          <div className="intro-text">
            <div className="fruit-section-bottom">
              {whiteonion.map((item) => (
                <div className="fruit-section-info dehydrated" key={item.name}>
                  <img src={item.img} alt="" />
                  <h1 style={{ fontSize: "30px", fontWeight: "500" }}>
                    {item.name}
                  </h1>

                  <p style={{ fontWeight: "400" }}>{item.text}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="see-more-content">
          <small>
            <u>slide here</u>
          </small>
          <KeyboardDoubleArrowRightIcon />
        </div>
        <hr
          style={{
            width: "100%",
            borderBottom: "2px solid #000",
            marginBottom: "5%",
          }}
        />
        <p className="primary-subheading"></p>

        <div className="work-section-top">
          <h1 className="primary-heading"> Dehydrated Red Onion</h1>
          <div className="fruit-images">
            <div className="fruit1">
              <img
                src="https://maharajadehydration.com/wp-content/uploads/sites/381/2021/09/Red-onion-Kibbled-m20.jpg"
                alt="is-corn-grain-1544222258"
                border="0"
              />
            </div>
          </div>
          <p>
            With their deep purple outer skin and lovely red coloured flesh,
            these have a peppery and spicy flavour when raw. Their layers are
            less tender. But, if cooked, they taste milder. Red onions are often
            used, for their lovely colour in raw preparations too
          </p>
        </div>
        <div className="fruit-container">
          <div className="intro-text">
            <div className="fruit-section-bottom">
              {redonion.map((item) => (
                <div className="fruit-section-info dehydrated" key={item.name}>
                  <img src={item.img} alt="" />
                  <h1 style={{ fontSize: "30px", fontWeight: "500" }}>
                    {item.name}
                  </h1>

                  <p style={{ fontWeight: "400" }}>{item.text}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="see-more-content">
          <small>
            <u>slide here</u>
          </small>
          <KeyboardDoubleArrowRightIcon />
        </div>
        <hr
          style={{
            width: "100%",
            borderBottom: "2px solid #000",
            marginBottom: "5%",
          }}
        />
        <p className="primary-subheading"></p>

        <div className="work-section-top">
          <h1 className="primary-heading"> Dehydrated Pink Onion</h1>
          <div className="fruit-images">
            <div className="fruit1">
              <img
                src="https://5.imimg.com/data5/HJ/GD/YY/SELLER-46244442/dehydrated-pink-onion-flakes-500x500.jpeg"
                alt="is-corn-grain-1544222258"
                border="0"
              />
            </div>
          </div>
          <p>
            Attractive in pink colour, they taste uniquely taste sweet with a
            fruity aroma and juicy texture. They lack the sharp, astringent
            taste of other onions, which is why they can be eaten plain, as they
            taste fantastic once thinly sliced.
          </p>
        </div>
        <div className="fruit-container">
          <div className="intro-text">
            <div className="fruit-section-bottom">
              {pinkonion.map((item) => (
                <div className="fruit-section-info dehydrated" key={item.name}>
                  <img src={item.img} alt="" />
                  <h1 style={{ fontSize: "30px", fontWeight: "500" }}>
                    {item.name}
                  </h1>

                  <p style={{ fontWeight: "400" }}>{item.text}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="see-more-content">
          <small>
            <u>slide here</u>
          </small>
          <KeyboardDoubleArrowRightIcon />
        </div>
        <hr
          style={{
            width: "100%",
            borderBottom: "2px solid #000",
            marginBottom: "5%",
          }}
        />
        <div className="work-section-top" ref={garlicref} id="scrollgarlic">
          <h1 className="primary-heading" ref={h1Ref2}>
            {" "}
            Dehydrated Garlic
          </h1>
          <div className="fruit-images">
            <div className="fruit1">
              <img
                src="https://5.imimg.com/data5/ANDROID/Default/2022/1/IU/JM/SN/96058065/product-jpeg-500x500.jpg"
                alt="is-corn-grain-1544222258"
                border="0"
              />
            </div>
          </div>
          <p>
            Dehydrated Garlic can be further value added by producing Dehydrated
            Flakes , Chopped , Minced & Powder, It is easy to pack, Quality
            Controlled And Transported easily for Global Destinations.
            Dehydrated Garlic are used Extensively in Pickles, Curries, Sauces,
            Medicine. Garlic Powder used in Soups, Sauces, Seasoning, and Meat
            Products and Suitable for varied food Preparations, Particularly
            When Strong Garlic Flavour and Taste is desired.
          </p>
        </div>
        <div className="fruit-container">
          <div className="intro-text">
            <div className="fruit-section-bottom">
              {garlic.map((item) => (
                <div className="fruit-section-info dehydrated" key={item.name}>
                  <img src={item.img} alt="" />
                  <h1 style={{ fontSize: "30px", fontWeight: "500" }}>
                    {item.name}
                  </h1>

                  <p style={{ fontWeight: "400" }}>{item.text}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="see-more-content">
          <small>
            <u>slide here</u>
          </small>
          <KeyboardDoubleArrowRightIcon />
        </div>
        <hr
          style={{
            width: "100%",
            borderBottom: "2px solid #000",
            marginBottom: "5%",
          }}
        />
        <div className="work-section-top" ref={spicesref} id="scrollspices">
          <h1 className="primary-heading" ref={h1Ref3}>
            Spices & Seasoning
          </h1>
          <div className="fruit-images">
            <div className="fruit1">
              <div className="one">
                <img
                  src="https://www.idukkispicesonline.com/wp-content/uploads/2017/12/Black-Pepper-Powder-1.jpg"
                  alt="is-corn-grain-1544222258"
                  border="0"
                />
                <p>Black Pepper powder</p>
              </div>
              <div className="one">
                <img
                  src="https://5.imimg.com/data5/SELLER/Default/2021/1/AJ/PL/OL/120968194/green-cardamom-powder-500x500.jpeg"
                  alt="is-corn-grain-1544222258"
                  border="0"
                />
                <p>Cardamom powder</p>
              </div>
              <div className="one">
                <img
                  src="https://www.funfoodfrolic.com/wp-content/uploads/2020/09/Garam-Masala-Blog-Thumbnail-500x500.jpg"
                  alt="is-corn-grain-1544222258"
                  border="0"
                />
                <p>Garam Masala</p>
              </div>
            </div>
            <div className="fruit1">
              <div className="one">
                <img
                  src="https://5.imimg.com/data5/CK/YW/ZI/SELLER-608905/jaljeera-powder-500x500.jpg"
                  alt="is-corn-grain-1544222258"
                  border="0"
                />
                <p>Jaljeera powder</p>
              </div>
              <div className="one">
                <img
                  src="https://cdn.shopify.com/s/files/1/0266/9979/2418/products/redchillipowder_grande.jpg?v=1591533204"
                  alt="is-corn-grain-1544222258"
                  border="0"
                />
                <p>Red chilly</p>
              </div>
              <div className="one">
                <img
                  src="https://www.sattvasugandha.com/wp-content/uploads/2021/03/TURMERIC-Slides-V3_2-1-1.jpg"
                  alt="is-corn-grain-1544222258"
                  border="0"
                />
                <p>Turmeric Powder</p>
              </div>
            </div>
          </div>

          <p>
            Spices are aromatic flavorings from seeds, fruits, bark, rhizomes,
            and other plant parts. Used in to season and preserve food, and as
            medicines, dyes, and perfumes, spices have been highly valued as
            trade goods for thousands of years
          </p>
        </div>
        <hr
          style={{
            width: "100%",
            borderBottom: "2px solid #000",
            marginBottom: "5%",
          }}
        />
        <div className="work-section-top" ref={driedref} id="scrolldried">
          <h1 className="primary-heading" ref={h1Ref4}>
            Spray Dried Fruits & vegetables
          </h1>
          <div className="fruit-images">
            <div className="fruit1">
              <div className="one">
                <img
                  src="https://4.imimg.com/data4/NH/GE/MY-26720697/banana-powder-500x500.jpg"
                  alt="is-corn-grain-1544222258"
                  border="0"
                />
                <p>Banana powder</p>
              </div>
              <div className="one">
                <img
                  src="https://5.imimg.com/data5/EC/VI/MY-14379234/pineapple-powder-500x500.jpg"
                  alt="is-corn-grain-1544222258"
                  border="0"
                />
                <p>Pineapple powder</p>
              </div>
              <div className="one">
                <img
                  src="https://5.imimg.com/data5/EM/UB/BP/SELLER-94661056/lemon-powder-spray-dried--500x500.jpg"
                  alt="is-corn-grain-1544222258"
                  border="0"
                />
                <p>Lemon powder</p>
              </div>
            </div>
            <div className="fruit1">
              <div className="one">
                <img
                  src="https://5.imimg.com/data5/SELLER/Default/2021/2/NP/IO/WD/122459560/tamarind-powder-500x500.jpg"
                  alt="is-corn-grain-1544222258"
                  border="0"
                />
                <p>Tamarind masala</p>
              </div>
              <div className="one">
                <img
                  src="https://maharanafoods.com/wp-content/uploads/2021/01/Mango-Powder.jpg"
                  alt="is-corn-grain-1544222258"
                  border="0"
                />
                <p>Mango powder</p>
              </div>
              <div className="one">
                <img
                  src="https://cdn.shopify.com/s/files/1/2395/7673/products/APPLEHERBALPOWDER.jpg?v=1633605083"
                  alt="is-corn-grain-1544222258"
                  border="0"
                />
                <p>Mud apple powder</p>
              </div>
            </div>
          </div>

          <p>
            spray drying is to increase the shelf life and easy handling of
            juices. In the present paper, the studies carried out so far on
            spray drying of various fruits and vegetables are reported. The
            major fruit juices dried are mango, banana, orange, guava, bayberry,
            watermelon, pineapple, etc.
          </p>
        </div>
        <hr
          style={{
            width: "100%",
            borderBottom: "2px solid #000",
            marginBottom: "5%",
          }}
        />
        <div className="work-section-top">
          <h1 className="primary-heading" ref={h1Ref5}>
            Other Dehydrated Vegetables
          </h1>
          <div className="fruit-images">
            <div className="fruit1">
              <div className="one">
                <img
                  src="https://cdn.shopify.com/s/files/1/0082/7159/3590/products/Fenugreek-Powder_600x.jpg"
                  alt="is-corn-grain-1544222258"
                  border="0"
                />
                <p>Fenugreek Powder</p>
              </div>
              <div className="one">
                <img
                  src="https://5.imimg.com/data5/XU/JW/MY-1459802/dehydrated-ginger-powder-500x500.jpg"
                  alt="is-corn-grain-1544222258"
                  border="0"
                />
                <p>Dehydrated Ginger powder</p>
              </div>
              <div className="one">
                <img
                  src="https://5.imimg.com/data5/IS/BT/MY-8983582/red-chilli-flake-500x500.jpg"
                  alt="is-corn-grain-1544222258"
                  border="0"
                />
                <p>Red chilly flakes</p>
              </div>
            </div>
            <div className="fruit1">
              <div className="one">
                <img
                  src="https://4.bp.blogspot.com/-ADYvARCHUlE/Us4M3ko-z1I/AAAAAAAAA-w/G3pa8N83te8/s1600/beetroot+masala+3.jpg"
                  alt="is-corn-grain-1544222258"
                  border="0"
                />
                <p>Beetroot masala</p>
              </div>
              <div className="one">
                <img
                  src="https://5.imimg.com/data5/RP/YM/WH/SELLER-5508788/dehydrated-mint-powder-500x500.jpg"
                  alt="is-corn-grain-1544222258"
                  border="0"
                />
                <p>Mint powder</p>
              </div>
              <div className="one">
                <img
                  src="https://5.imimg.com/data5/SELLER/Default/2021/1/DQ/PJ/CM/45035573/green-chilli-500x500.jpg"
                  alt="is-corn-grain-1544222258"
                  border="0"
                />
                <p>Dehydrated Green chilly powder</p>
              </div>
            </div>
          </div>

          <p>
            Any vegetable that has been dehydrated or dried in order to extend
            the shelf life and to concentrate the flavor. Popular dried
            vegetables include sun-dried tomatoes, onions, carrots, and chiles.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Dehydrated_Products;

import React, { useEffect, useRef, useState } from "react";
import "./Navbar.css";
import { Link } from "react-router-dom";
import Logo from "../Assets/logo.png";
import { HiOutlineBars3 } from "react-icons/hi2";
import { Icon } from "@iconify/react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

import $ from "jquery";
function Navbar() {
  const [toggle, setToggle] = useState(false);
  const [toggle2, setToggle2] = useState(false);
  const [toggle3, setToggle3] = useState(false);
  const [toggle4, setToggle4] = useState(false);
  const [toggle5, setToggle5] = useState(false);
  const calltoggle = () => {
    setToggle(!toggle);
  };
  const calltoggle2 = () => {
    setToggle2(!toggle2);
  };
  const calltoggle3 = () => {
    setToggle3(!toggle3);
  };

  function animation() {
    var tabsNewAnim = $("#navbarSupportedContent");
    var activeItemNewAnim = tabsNewAnim.find(".active");
    var activeWidthNewAnimHeight = activeItemNewAnim.innerHeight();
    var activeWidthNewAnimWidth = activeItemNewAnim.innerWidth();
    var itemPosNewAnimTop = activeItemNewAnim.position();
    var itemPosNewAnimLeft = activeItemNewAnim.position();
    $(".hori-selector").css({
      top: itemPosNewAnimTop.top + "px",
      left: itemPosNewAnimLeft.left + "px",
      height: activeWidthNewAnimHeight + "px",
      width: activeWidthNewAnimWidth + "px",
    });
    $("#navbarSupportedContent").on("click", "li", function (e) {
      $("#navbarSupportedContent ul li").removeClass("active");
      $(this).addClass("active");
      var activeWidthNewAnimHeight = $(this).innerHeight();
      var activeWidthNewAnimWidth = $(this).innerWidth();
      var itemPosNewAnimTop = $(this).position();
      var itemPosNewAnimLeft = $(this).position();
      $(".hori-selector").css({
        top: itemPosNewAnimTop.top + "px",
        left: itemPosNewAnimLeft.left + "px",
        height: activeWidthNewAnimHeight + "px",
        width: activeWidthNewAnimWidth + "px",
      });
    });
  }

  const [isSticky, setIsSticky] = useState(false);
  const navbarRef = useRef(null);

  const handleScroll = () => {
    const navbar = navbarRef.current;
    const top = navbar.offsetTop;
    setIsSticky(window.scrollY >= top);
  };

  useEffect(() => {
    animation();
    $(window).on("resize", function () {
      setTimeout(function () {
        animation();
      }, 500);
    });
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    let handler = (e) => {
      if (!navbarRef.current.contains(e.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", handler);
  }, []);
  const [hover, setHover] = useState(false);
  const toggleHover = () => {
    setHover(!hover);
  };
  const linkStyle = {
    color: hover ? "#ed3d24" : "#ed3d24",
    fontWeight: "600",
  };
  const [open, setOpen] = useState(false);
  return (
    <nav
      className={
        isSticky
          ? "stickyy navbar navbar-expand-lg navbar-mainbg"
          : "stickyy navbar navbar-expand-lg navbar-mainbg"
      }
      ref={navbarRef}
      style={{
        paddingRight: "5%",
        zIndex: "99999",
        display: "flex",
      }}
    >
      <Link className="navbar-brand navbar-logo" to="/">
        <img src={Logo} alt="" />
      </Link>

      <div
        className="collapse navbar-collapse"
        id="navbarSupportedContent"
        style={{
          justifyContent: "start",
          width: "100%",
        }}
      >
        <ul className="navbar-nav ml-auto">
          <div className="hori-selector">
            <div className="left"></div>
            <div className="right"></div>
          </div>

          <li className="nav-item active">
            <Link className="nav-link" to="/">
              <i className="fas fa-tachometer-alt"></i>Home
            </Link>
          </li>

          <li className="nav-item">
            <Link className="nav-link" to="/about">
              <i className="far fa-address-book"></i>Who we are
            </Link>
          </li>

          <li className="nav-item">
            <div className="services">
              <Link className="nav-link" to="/products">
                <i className="far fa-clone"></i>
                <span
                  style={linkStyle}
                  onMouseEnter={toggleHover}
                  onMouseLeave={toggleHover}
                >
                  Products
                </span>
              </Link>
              <ul className="dropdown">
                <li>
                  <div className="fruits-hover">
                    <Link to="/fruits" className="remove_decoration">
                      Fresh Fruits
                      <Icon
                        icon="material-symbols:keyboard-double-arrow-right-rounded"
                        className="right-arrow"
                        color="#ed3d24"
                      />
                    </Link>

                    <ul className="fruits-dropdown">
                      <li>
                        <Link to="/fruits#scrollmango">Mango</Link>
                      </li>
                      <li>
                        <Link to="/fruits#scrollgrapes">Grapes</Link>
                      </li>
                      <li>
                        <Link to="/fruits#scrollpomegranate">Pomegranate</Link>
                      </li>
                      <li>
                        <Link to="/fruits#scrollbanana">Banana</Link>
                      </li>
                      <li>
                        <Link to="/fruits#scrollstrawberry">Strawberry</Link>
                      </li>
                      <li>
                        <Link to="/fruits#scrollblueberry">Blueberry</Link>
                      </li>
                    </ul>
                  </div>
                </li>
                <li>
                  <div className="spices-hover">
                    <Link to="/spices" className="remove_decoration">
                      Spices
                      <Icon
                        icon="material-symbols:keyboard-double-arrow-right-rounded"
                        className="right-arrow"
                        color="#ed3d24"
                      />
                    </Link>
                    <ul className="spices-dropdown">
                      <li>
                        <Link to="/spices#scrollred">Red Dried Chilli</Link>
                      </li>
                      <li>
                        <Link to="/spices#scrollturmeric">Turmeric</Link>
                      </li>
                      <li>
                        <Link to="/spices#scrollginger">Ginger</Link>
                      </li>
                      <li>
                        <Link to="/spices#scrollseasame">Seasame Seeds</Link>
                      </li>
                      <li>
                        <Link to="/spices#scrollcardamom">Cardamom</Link>
                      </li>
                      <li>
                        <Link to="/spices#scrollcumin">Cumin Seeds</Link>
                      </li>
                    </ul>
                  </div>
                </li>
                <li>
                  <Link to="/super-food" className="remove_decoration">
                    SuperFood
                  </Link>
                </li>
                <li>
                  <div className="dehydrated-hover">
                    <Link to="/dehydrated" className="remove_decoration">
                      Dehydrated
                      <Icon
                        icon="material-symbols:keyboard-double-arrow-right-rounded"
                        className="right-arrow"
                        color="#ed3d24"
                      />
                    </Link>
                    <ul className="dehydrated-dropdown">
                      <li>
                        <Link to="/dehydrated#scrollonion">Onion</Link>
                      </li>
                      <li>
                        <Link to="/dehydrated#scrollgarlic">Garlic</Link>
                      </li>
                      <li>
                        <Link to="/dehydrated#scrollspices">
                          Spices & Seasoning
                        </Link>
                      </li>
                      <li>
                        <Link to="/dehydrated#scrolldried">Dried Fruits</Link>
                      </li>
                    </ul>
                  </div>
                </li>
                <li>
                  <Link to="/frozen-fruit" className="remove_decoration">
                    Frozen Range
                  </Link>
                </li>
                <li>
                  <Link to="/rice" className="remove_decoration">
                    Rice
                  </Link>
                </li>
                <li>
                  <Link to="/vegetables" className="remove_decoration">
                    Vegetables
                  </Link>
                </li>
              </ul>
            </div>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/partner">
              <i className="far fa-copy"></i>Partner Us
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/certificate">
              <i className="far fa-copy"></i>Certifications
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/contact">
              <i className="far fa-copy"></i>Contact
            </Link>
          </li>
        </ul>
      </div>

      <div className="navbar-menu-container">
        <HiOutlineBars3
          onClick={() => {
            setOpen(true);
          }}
          className="hamburger"
        />
      </div>
      {open && (
        <div className="main-drawer">
          <div
            className={`navbar-drawer ${open ? "active" : "inactive"}`}
            style={{ overflowY: "auto" }}
          >
            <ul>
              <div className="item">
                <li
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  <Link to="/">
                    <Icon
                      icon="material-symbols:home"
                      className="navbar-icon"
                    />
                    Home
                  </Link>
                </li>
              </div>
              <li
                onClick={() => {
                  setOpen(false);
                }}
              >
                <Link to="/about">
                  <Icon
                    icon="material-symbols:person"
                    className="navbar-icon"
                  />
                  Who we are
                </Link>
              </li>
              <li>
                <Link
                  to="/products"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  <Icon icon="dashicons:products" className="navbar-icon" />
                  Products
                </Link>
                <div onClick={calltoggle} className="toggle-navbar">
                  {toggle ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </div>
              </li>
              {toggle && (
                <ul>
                  <li>
                    <Link
                      to="/fruits"
                      onClick={() => {
                        setOpen(false);
                      }}
                    >
                      <Icon
                        icon="game-icons:fruit-bowl"
                        className="navbar-icon"
                      />
                      Fruits
                    </Link>
                    <div onClick={calltoggle2}>
                      {toggle2 ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </div>
                  </li>
                  {toggle2 && (
                    <ul>
                      <li
                        onClick={() => {
                          setOpen(false);
                        }}
                      >
                        <Link to="/fruits#scrollmango">
                          <Icon icon="openmoji:mango" className="navbar-icon" />
                          Mango
                        </Link>
                      </li>
                      <li>
                        <Link to="/fruits#scrollgrapes">
                          <Icon icon="noto-v1:grapes" className="navbar-icon" />
                          Grapes
                        </Link>
                      </li>
                      <li>
                        <Link to="/fruits#scrollpomegranate">
                          <Icon
                            icon="openmoji:pomegranate"
                            className="navbar-icon"
                          />
                          Pomegranate
                        </Link>
                      </li>
                      <li>
                        <Link to="/fruits#scrollbanana">
                          <Icon icon="noto:banana" className="navbar-icon" />
                          Banana
                        </Link>
                      </li>
                      <li>
                        <Link to="/fruits#scrollstrawberry">
                          <Icon
                            icon="openmoji:strawberry"
                            className="navbar-icon"
                          />
                          Strawberry
                        </Link>
                      </li>
                      <li>
                        <Link to="/fruits#scrollblueberry">
                          <Icon
                            icon="octicon:dot-fill-16"
                            className="navbar-icon"
                            color="#4e6ead"
                          />
                          Blueberry
                        </Link>
                      </li>
                    </ul>
                  )}
                  <li>
                    <Link
                      to="/spices"
                      onClick={() => {
                        setOpen(false);
                      }}
                    >
                      <Icon
                        icon="game-icons:hot-spices"
                        className="navbar-icon"
                      />
                      Spices
                    </Link>
                    <div onClick={calltoggle3}>
                      {toggle3 ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </div>
                  </li>
                  {toggle3 && (
                    <ul>
                      <li>
                        <Link to="/spices#scrollred">
                          <Icon
                            icon="mdi:chilli-medium"
                            className="navbar-icon"
                          />
                          Red Dried Chilli
                        </Link>
                      </li>
                      <li>
                        <Link to="/spices#scrollturmeric">
                          <Icon
                            icon="game-icons:tree-roots"
                            className="navbar-icon"
                          />
                          Turmeric
                        </Link>
                      </li>
                      <li>
                        <Link to="/spices#scrollginger">
                          <Icon icon="tabler:wood" className="navbar-icon" />
                          Ginger
                        </Link>
                      </li>
                      <li>
                        <Link to="/spices#scrollseasame">
                          <Icon
                            icon="icon-park-twotone:hold-seeds"
                            className="navbar-icon"
                          />
                          Seasame Seeds
                        </Link>
                      </li>
                      <li>
                        <Link to="/spices#scrollcardamom">
                          <Icon
                            icon="icon-park-solid:fruiter"
                            className="navbar-icon"
                          />
                          Cardamom
                        </Link>
                      </li>
                      <li>
                        <Link to="/spices#scrollcumin">
                          <Icon
                            icon="icon-park-twotone:hold-seeds"
                            className="navbar-icon"
                          />
                          Cumin Seeds
                        </Link>
                      </li>
                    </ul>
                  )}
                  <li
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    <Link to="/super-food">
                      <Icon icon="mdi:food-steak" className="navbar-icon" />
                      Super Food
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/dehydrated"
                      onClick={() => {
                        setOpen(false);
                      }}
                    >
                      <Icon
                        icon="material-symbols:food-bank"
                        className="navbar-icon"
                      />
                      Dehydrated
                    </Link>
                    <div
                      onClick={() => {
                        setToggle5(!toggle5);
                      }}
                    >
                      {toggle5 ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </div>
                  </li>
                  {toggle5 && (
                    <ul>
                      <li>
                        <Link to="/dehydrated#scrollonion">
                          <Icon
                            icon="fluent-emoji-high-contrast:onion"
                            className="navbar-icon"
                          />
                          Onion
                        </Link>
                      </li>
                      <li>
                        <Link to="/dehydrated#scrollgarlic">
                          <Icon
                            icon="game-icons:garlic"
                            className="navbar-icon"
                          />
                          Garlic
                        </Link>
                      </li>
                      <li>
                        <Link to="/dehydrated#scrollspices">
                          <Icon
                            icon="game-icons:cool-spices"
                            className="navbar-icon"
                          />
                          Spices & Seasoning
                        </Link>
                      </li>
                      <li>
                        <Link to="/dehydrated#scrolldried">
                          <Icon
                            icon="carbon:fruit-bowl"
                            className="navbar-icon"
                          />
                          Dried Fruits
                        </Link>
                      </li>
                    </ul>
                  )}
                  <li>
                    <Link
                      to="/frozen-fruit"
                      onClick={() => {
                        setOpen(false);
                      }}
                    >
                      <Icon
                        icon="game-icons:frozen-orb"
                        className="navbar-icon"
                      />
                      Frozen Range
                    </Link>
                    <div
                      onClick={() => {
                        setToggle4(!toggle4);
                      }}
                    >
                      {toggle4 ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </div>
                  </li>
                  {toggle4 && (
                    <ul>
                      <li>
                        <Link to="/frozen-fruit">
                          <Icon
                            icon="game-icons:frozen-ring"
                            className="navbar-icon"
                          />
                          Frozen Fruit
                        </Link>
                      </li>
                      <li>
                        <Link to="/vegetables">
                          <Icon
                            icon="icon-park-outline:vegetable-basket"
                            className="navbar-icon"
                          />
                          Frozen Vegetable
                        </Link>
                      </li>
                    </ul>
                  )}
                  <li
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    <Link to="/rice">
                      <Icon icon="bxs:bowl-rice" className="navbar-icon" />
                      Rice
                    </Link>
                  </li>
                  <li
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    <Link to="/vegetables">
                      <Icon
                        icon="fluent:bowl-salad-24-filled"
                        className="navbar-icon"
                      />
                      Vegetables
                    </Link>
                  </li>
                </ul>
              )}
              <li
                onClick={() => {
                  setOpen(false);
                }}
              >
                <Link to="/partner">
                  <Icon
                    icon="pepicons-print:handshake"
                    className="navbar-icon"
                  />
                  Partner Us
                </Link>
              </li>
              <li
                onClick={() => {
                  setOpen(false);
                }}
              >
                <Link to="/certificate">
                  <Icon
                    icon="fluent:certificate-24-filled"
                    hFlip={true}
                    className="navbar-icon"
                  />
                  Certificates
                </Link>
              </li>
              <li
                onClick={() => {
                  setOpen(false);
                }}
              >
                <Link to="/contact">
                  <Icon
                    icon="material-symbols:phone-enabled-sharp"
                    hFlip={true}
                    className="navbar-icon"
                  />
                  Contact
                </Link>
              </li>
            </ul>
          </div>
        </div>
      )}
    </nav>
  );
}
export default Navbar;

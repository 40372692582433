/* eslint-disable import/no-anonymous-default-export */
import First from "../Assets/spices.jpg";
import Spices1 from "../Assets/Chilli-Flaks.jpg";
import Spices2 from "../Assets/red.jpg";

export default [
  {
    urls: First,
  },
  {
    urls: Spices1,
  },
  {
    urls: Spices2,
  },
];

import React, { useEffect } from "react";
import video from "../Assets/overall.mp4";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
function Rice() {
  const rice = [
    {
      name: "1121 Sella Basmati Rice",
      text: "It is a premium quality, long-grain rice known for its aromatic fragrance, rich taste, and fluffy texture that originates from the Indian subcontinent.",
      img: "https://pitambarfoods.com/wp-content/uploads/2018/07/1121-Sella-Wand.jpg",
    },
    {
      name: "1509 Sella Basmati Rice",
      text: "It is a popular variety of long-grain rice that has been parboiled and partially precooked to retain its nutritional value, unique aroma, and fluffy texture after cooking.",
      img: "https://www.midasoverseas.com/wp-content/uploads/2019/08/1509_Extralong_Sella_basmati.jpg",
    },
    {
      name: "Parboiled rice",
      text: "It is a type of rice that has been partially boiled in the husk, resulting in a nutritious and easy-to-cook grain.",
      img: "https://www.mealgarden.com/media/recipe/2015/02/bigstock-plain-boiled-rice-51992161.jpeg",
    },
    {
      name: "IR 64",
      text: "This rice is a popular high-yielding, semi-dwarf rice variety developed by the International Rice Research Institute (IRRI) that has been widely cultivated in Asia since the 1980s.",
      img: "https://www.midasoverseas.com/wp-content/uploads/2019/08/DSC_0121-300x300.jpg",
    },
  ];
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div>
      <div className="work-section-top">
        <p className="primary-subheading">
          <img
            src="https://i.ibb.co/wKScgFm/rice111.jpg"
            alt="rice111"
            border="0"
          />
        </p>
        <h1 className="primary-heading">Rice/Paddy</h1>
        <div className="work-video">
          <video controls={false} width="100%" loop muted autoPlay playsInline>
            <source src={video} type="video/mp4" />
            Sorry your browser not support video
          </video>
        </div>
        <p>
          Rice, (Oryza sativa), edible starchy cereal grain and the grass plant
          (family Poaceae) by which it is produced. It is the most important
          cereal crop in the developing world and is the staple food of over
          half the world's population. It is generally considered a semi-aquatic
          annual grass plant.
        </p>
      </div>
      <div className="fruit-images">
        <div className="fruit1">
          <div className="one">
            <img
              src="https://i.ibb.co/TwyJCj9/images-1.jpg"
              alt="images-1"
              border="0"
            />
            <p>1121 Basmati Rice</p>
          </div>
          <div className="one">
            {" "}
            <img
              src="https://2.wlimg.com/product_images/bc-full/2022/11/7730089/1509-sella-basmati-rice-1669702732-6647089.jpeg"
              alt="rice"
            />
            <p>1509 Basmati Rice</p>
          </div>
        </div>
      </div>
      <p
        className="chart"
        style={{ margin: "5% 0% 5% 10%", textAlign: "left" }}
      >
        SEASONAL CHART
      </p>
      <div style={{ overflowX: "auto", marginBottom: "6%" }}>
        <table style={{ marginLeft: "5%" }}>
          <tbody>
            <tr>
              <th>Rice</th>
              <th>Jan</th>
              <th>Feb</th>
              <th>Mar</th>
              <th>Apr</th>
              <th>May</th>
              <th>Jun</th>
              <th>Jul</th>
              <th>Aug</th>
              <th>Sep</th>
              <th>Oct</th>
              <th>Nov</th>
              <th>Dec</th>
            </tr>
            <tr>
              <td>Rice</td>
              <td>-</td>
              <td>🌾</td>
              <td>🌾</td>
              <td>-</td>
              <td>🌾</td>
              <td>🌾</td>
              <td>🌾</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>🌾</td>
              <td>🌾</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="fruit-container">
        <div className="intro-text">
          <div className="fruit-section-bottom">
            {rice.map((item) => (
              <div
                className="fruit-section-info"
                key={item.name}
                style={{
                  backgroundImage: `url(${item.img})`,
                }}
              >
                <h1>{item.name}</h1>
                <p>{item.text}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="see-more-content">
        <small>
          <u>slide here</u>
        </small>
        <KeyboardDoubleArrowRightIcon />
      </div>
    </div>
  );
}

export default Rice;

/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { Icon } from "@iconify/react";
import video1 from "../Assets/Mango.mp4";
import video2 from "../Assets/Grapes.mp4";
import video3 from "../Assets/pomegranate.mp4";
import video4 from "../Assets/banana.mp4";
import video5 from "../Assets/strawberry.mp4";
import video6 from "../Assets/blueberry.mp4";
import img1 from "../Assets/mango_kh.jpg";
import img2 from "../Assets/ratnagiri.png";
import img3 from "../Assets/kesar.jpg";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
//import SearchBar from "./SearchBar";

function Fruits() {
  const mangoref = useRef(null);
  const grapesref = useRef(null);
  const pomegranateref = useRef(null);
  const bananaref = useRef(null);
  const strawberryref = useRef(null);
  const blueberryref = useRef(null);
  const location = useLocation();
  const [inputValue, setInputValue] = useState("");
  const h1Ref1 = useRef(null);
  const h1Ref2 = useRef(null);
  const h1Ref3 = useRef(null);
  const h1Ref4 = useRef(null);
  const h1Ref5 = useRef(null);
  const h1Ref6 = useRef(null);
  // const [searchInput, setSearchInput] = useState("");
  const blue = [
    {
      name: "Varieties",
      text: "India produces a variety of blueberry cultivars that are suitable for export, including Bluecrop, Duke, and Chandler.",
    },
    {
      name: "Export quality",
      text: "Indian blueberries are known for their high quality and freshness which makes them popular worldwide.At Eosworld the blueberries are carefully handpicked sorted.",
    },
    {
      name: "Appearance",
      text: "Indian blueberries are small, round, and have a dark blue color. The skin is smooth and firm, and the flesh is juicy and tender",
    },
    {
      name: "Taste",
      text: "Indian blueberries have a sweet and tangy flavor, with a slightly acidic taste. They are ideal for a variety of culinary applications,from baking to snacking.",
    },
    {
      name: "Harvesting season",
      text: " Indian blueberries are typically harvested from November to March ,depending on the region and growing conditions.",
    },
    {
      name: "Nutrition",
      text: "Indian blueberries are a rich source of antioxidants vitamins C and K as well as dietary fiber Nutrition.They are low in calories and fat.",
    },
    {
      name: "Uses",
      text: "Used in a variety of culinary applications. Mostly used in baking ,such as in muffins, cakes. They are also great for snacking, adding to yogurt, smoothies, and salads",
    },
  ];
  const strawberry = [
    {
      name: "Taste",
      text: "Indian strawberries are known for their sweet and tangy flavor, with a hint of acidity that makes them perfect for a variety of culinary applications.",
    },
    {
      name: "Harvesting season",
      text: "Indian strawberries are harvested from November to May, depending on the growing region and climate.",
    },
    {
      name: "Nutrition",
      text: "Eos World's Indian strawberries are a rich source of vitamins C and K, as well as antioxidants and dietary fiber.",
    },
    {
      name: " Uses",
      text: "Eos World strawberries are versatile fruits that can be used in a variety of culinary applications. They are commonly used to make jams, jellies,moothies, and other desserts.",
    },
    {
      name: "Export quality",
      text: "At Eos World, strawberries are known for their high quality and freshness, which makes them popular worldwide, here strawberries are carefully handpicked, sorted.",
    },
  ];
  const grapes = [
    {
      name: "Thompson / White Seedless Indian grapes",
      img: "https://i.ibb.co/M714mKD/Thomson-Seedless.jpg",
      text: "Sweet, juicy, and seedless - Thompson grapes are the perfect snack.",
    },
    {
      name: "Sonaka Seedless Indian grapes",
      img: "https://i.ibb.co/b1vHb1z/Sonaka-Seedless.jpg",
      text: "Indulge in the refreshing taste of Sonaka seedless grapes from India.",
    },
    {
      name: "Jumbo Seedless Indian grapes",
      img: "https://i.ibb.co/VSDMg19/Jumbo-Seedless.jpg",
      text: " Jumbo seedless grapes: big on flavor and nutrition.",
    },
    {
      name: "Flame Seedless Indian Grapes",
      img: "https://i.ibb.co/r5Lc7sh/Flame-Seedless.jpg",
      text: " Experience the burst of flavor with Flame seedless grapes from India.",
    },
    {
      name: " Sharad / Black Seedless Indian grapes",
      img: "https://i.ibb.co/74L3YgP/Sharad-Seedless.jpg",
      text: " Savor the rich and intense taste of Sharad black seedless grapes.",
    },
  ];
  const banana = [
    {
      name: " Varieties",
      text: "India produces a wide range of banana varieties that are suitable for export, including Cavendish, Robusta, and Grand Nain.",
    },
    {
      name: "Appearance",
      text: "Indian bananas are medium-sized, with a curved shape and yellow color. The skin is smooth and firm, and the flesh is soft and creamy.",
    },
    {
      name: "Taste",
      text: "Indian bananas have a sweet and creamy flavor, with a slightly tangy taste. They are ideal for a variety of culinary applications, from snacking to cooking.",
    },
    {
      name: "Harvesting season",
      text: "Indian bananas are typically harvested year-round, depending on the region.",
    },
    {
      name: "Nutrition",
      text: " Indian bananas are a rich source of vitamins C and B6, as well as potassium and dietary fiber. They are low in calories and fat, making them a healthy and nutritious fruit.",
    },
    {
      name: "Uses",
      text: " Eos World Indian bananas are versatile fruits that can be used in a variety of culinary applications. They are commonly used in baking, such as in banana bread and cakes.",
    },
    {
      name: "Export quality",
      text: "Eos World Indian bananas are known for their high quality and freshness,Here they are carefully handpicked,sorted, and packed in export-worthy packaging.",
    },
  ];
  const pomegranate = [
    {
      name: "Health Benefits",
      text: "Pomegranates are rich in antioxidants, vitamins C and K, and may help improve heart health, lower blood pressure, and reduce inflammation.",
    },
    {
      name: "Export Packaging",
      text: "We understand the importance of packaging when it comes to exporting fresh produce. Our Pomegranates are packed in high-quality, remain fresh and retain their quality during transportation.",
    },
    {
      name: "Quality Assurance",
      text: "At our company, quality is of utmost importance. We have a team of experts who carefully inspect each Pomegranate to ensure that they meet our high-quality standards.",
    },
    {
      name: "Export Destinations",
      text: "We export our GI-tagged Pomegranates to various countries around the world. Some of our major export destinations include Europe, the Middle East, and Asia.",
    },
    {
      name: "Contact Us",
      text: " If you are interested in buying our high-quality GI-tagged Pomegranates from India, please get in touch with us. We would be happy to answer any questions you may have.",
    },
  ];
  const mango = [
    {
      name: "Alphonso Mango",
      text: "Indian Alphonso mango, also known as the “King of  Mangoes,” is a premium variety of mango that is grown primarily in the western state of Maharashtra in India. It has a distinct sweet and aromatic flavour,juicy texture, and a golden-yellow colour.",
      img: "https://cdn.shopify.com/s/files/1/0718/0356/8425/products/alphonso-mango-stock-image-1-1024x686-2.jpg",
    },
    {
      name: "Kesar Mango",
      text: "Kesar mango is another popular variety of mango that is grown mainly in the state of Gujarat in India. It has a unique sweet taste, soft texture, and an intense aroma that makes it a favorite among mango lovers worldwide. Kesar mangoes are also rich in vitamins.",
      img: "https://kj1bcdn.b-cdn.net/media/48567/kesar-mangoes-source-tradeindia.jpg",
    },
    {
      name: "Banganapalli mango",
      text: "Banganapalli mango, also known as Benishan or Safeda, is a popular mango variety that is grown primarily in the southern India. It is one of the largest and most commonly exported mango varieties from India, and it has a distinct sweet taste.",
      img: "https://live.staticflickr.com/5343/7216120486_07e718e4e6_b.jpg",
    },
    {
      name: "Totapuri Mango",
      text: "Totapuri mango is a unique and versatile fruit that is popular among mango lovers worldwide. Its tangy flavor, firm texture, and attractive appearance make it a favorite export variety from India. Totapuri mango is a popular export variety due to its tangy flavor.",
      img: "https://cpimg.tistatic.com/06058177/b/4/Totapuri-Mango.jpg",
    },
    {
      name: "Badami Mango",
      text: "Badami mango, also known as Alphonso of Karnataka, is a popular mango variety that is grown primarily in the southern Indian states. It is one of the most sought-after mango varieties in India, and it has a distinct sweet taste, aromatic fragrance, and an attractive yellow-orange color.",
      img: "https://images.jdmagicbox.com/quickquotes/images_main/premium-badami-mango-377184277-41ei6.jpeg",
    },
  ];

  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  useEffect(() => {
    if (location.hash === "#scrollgrapes") {
      grapesref.current.scrollIntoView({ behaviour: "smooth" });
    }
    if (location.hash === "#scrollpomegranate") {
      pomegranateref.current.scrollIntoView({ behaviour: "smooth" });
    }
    if (location.hash === "#scrollbanana") {
      bananaref.current.scrollIntoView({ behaviour: "smooth" });
    }
    if (location.hash === "#scrollstrawberry") {
      strawberryref.current.scrollIntoView({ behaviour: "smooth" });
    }
    if (location.hash === "#scrollblueberry") {
      blueberryref.current.scrollIntoView({ behaviour: "smooth" });
    }
  }, [location]);
  //search
  function scrollToMatchingH1Tag() {
    const h1Tags = [
      h1Ref1.current,
      h1Ref2.current,
      h1Ref3.current,
      h1Ref4.current,
      h1Ref5.current,
      h1Ref6.current,
    ];
    h1Tags.forEach((h1Tag) => {
      if (h1Tag.textContent.toLowerCase().includes(inputValue.toLowerCase())) {
        h1Tag.scrollIntoView({ behavior: "smooth" });
        setScrollPosition(h1Tag);
      }
    });
  }

  useEffect((e) => {
    const handleKeyPress = (e) => {
      if (e.key === "Enter") {
        scrollToMatchingH1Tag();
      }
    };
    document.addEventListener("keypress", handleKeyPress);
    //scrollToMatchingH1Tag();
    return () => {
      document.removeEventListener("keypress", handleKeyPress);
    };
  });
  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = (event) => {
    setScrollPosition(event.target.scrollTop);
  };

  const handleInputFocus = () => {
    window.scrollTo(0, scrollPosition);
  };
  return (
    <div>
      <div className="work-section-wrapper">
        <div className="work-section-top">
          <p className="primary-subheading">
            <img
              src="https://i.ibb.co/BzSw5c1/fruits-vegetables-word-concept-47191088.jpg"
              alt=""
            />
          </p>
          <div className="search-box">
            <input
              type="text"
              placeholder="Search..."
              value={inputValue}
              onChange={(event) => setInputValue(event.target.value)}
              onFocus={handleInputFocus}
              // onKeyPress={(event) => handleKeyPress(event)}
              className="input-text"
            />
            <Icon
              icon="ic:outline-search"
              className="search-icon"
              color="#fff"
            />
          </div>
          {/* <SearchBar /> */}
          <h1 className="primary-heading" ref={h1Ref1} onScroll={handleScroll}>
            Mango
          </h1>
          <div className="work-video" ref={mangoref}>
            <video
              controls={false}
              width="100%"
              loop
              muted
              autoPlay
              playsInline
            >
              <source src={video1} type="video/mp4" />
              Sorry your browser not support video
            </video>
          </div>
          <p>
            Indian varieties of mango are known for its unique flavour,
            sweetness and richness. India is the largest producer of mangoes in
            the world. We export many varieties but specially Ratnagiri
            (Alphonso) ,Devgad, Kesar etc. We can pack them in standard as well
            as customized packing
          </p>
        </div>
        <div className="fruit-images">
          <div className="fruit1">
            <div className="one">
              <img src={img1} alt="mango" />
              <p>Alphonso Mango</p>
            </div>
            <div className="one">
              {" "}
              <img src={img2} alt="mango" />
              <p>Alphonso Mango</p>
            </div>
          </div>
          <div className="fruit1">
            <div className="one ">
              <img src={img3} alt="mango" />
              <p>Keshar Mangoes</p>
            </div>
            <div className="one">
              <img
                src="https://i.ibb.co/ChjrWL5/totapuri-mango-1845900.jpg"
                alt="totapuri-mango-1845900"
                border="0"
              />

              <p>Totapuri Mangoes </p>
            </div>
          </div>
          <div className="fruit1">
            <div className="one ">
              <img
                src="https://i.ibb.co/6tQm2mh/banganapalli-mango.jpg"
                alt="banganapalli-mango"
                border="0"
              />
              <p>Banganapalli Mangoes</p>
            </div>
            <div className="one">
              <img
                src="https://i.ibb.co/0FnKPys/Whats-App-Image-2023-04-08-at-19-30-25.jpg"
                alt="mango"
              />
              <p>Ratnagiri Mangoes </p>
            </div>
          </div>
        </div>
        <p style={{ margin: "5% 0% 5% 10%", textAlign: "left" }}>PACKAGING</p>
        <div className="package">
          <div className="fruit1">
            <img
              src="https://i.ibb.co/bgj2Yc5/img-8373-2.jpg"
              alt="img-8373-2"
              border="0"
            />
          </div>
          <div className="fruit1">
            <img
              src="https://i.ibb.co/fN40Ztj/2021-04-21.png"
              alt="2021-04-21"
              border="0"
            />
          </div>
        </div>
        <p
          className="chart"
          style={{ margin: "5% 0% 5% 10%", textAlign: "left" }}
        >
          SEASONAL CHART
        </p>
        <div style={{ overflowX: "auto" }}>
          <table style={{ marginLeft: "5%" }}>
            <tbody>
              <tr>
                <th>Fruit</th>
                <th>Jan</th>
                <th>Feb</th>
                <th>Mar</th>
                <th>Apr</th>
                <th>May</th>
                <th>Jun</th>
                <th>Jul</th>
                <th>Aug</th>
                <th>Sep</th>
                <th>Oct</th>
                <th>Nov</th>
                <th>Dec</th>
              </tr>
              <tr>
                <td>mango</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>🥭</td>
                <td>🥭</td>
                <td>🥭</td>
                <td>🥭</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="fruit-container">
          <div className="intro-text">
            <div className="fruit-section-bottom">
              {mango.map((item) => (
                <div
                  className="fruit-section-info"
                  key={item.name}
                  style={{ backgroundImage: `url(${item.img})` }}
                >
                  <h1>{item.name}</h1>
                  <p>{item.text}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="see-more-content">
          <small>
            <u>slide here</u>
          </small>
          <KeyboardDoubleArrowRightIcon />
        </div>
        <hr
          style={{
            width: "100%",
            borderBottom: "2px solid #000",
            marginTop: "10%",
          }}
        ></hr>
        <div
          className="scroll"
          ref={grapesref}
          id="scrollgrapes"
          style={{ position: "absolute", top: "24.5%" }}
        ></div>
        <div className="work-section-top">
          <h1 className="primary-heading" ref={h1Ref2} onScroll={handleScroll}>
            Grapes
          </h1>
          <div className="work-video">
            <video
              controls={false}
              width="100%"
              loop
              muted
              autoPlay
              playsInline
              style={{ pointerEvents: "none" }}
            >
              <source src={video2} type="video/mp4" />
              Sorry your browser not support video
            </video>
          </div>
        </div>

        <div className="fruit-images">
          <div className="fruit1">
            <div className="one">
              <img
                src="https://i.ibb.co/b1vHb1z/Sonaka-Seedless.jpg"
                alt="Sonaka-Seedless"
                border="0"
              />
              <p>Sonaka-Seedless</p>
              <p>
                Indulge in the refreshing taste of Sonaka seedless grapes from
                India.
              </p>
            </div>
            <div className="one">
              <img
                src="https://i.ibb.co/VSDMg19/Jumbo-Seedless.jpg"
                alt="Jumbo-Seedless"
                border="0"
              />
              <p>Jumbo-Seedless</p>
              <p>Jumbo seedless grapes: big on flavor and nutrition.</p>
            </div>
            <div className="one">
              <img
                src="https://i.ibb.co/0yR7rVz/Crimson-Seedless.jpg"
                alt="Crimson-Seedless"
                border="0"
              />
              <p>Crimson-Seedless</p>
              <p>good levels of sweetness and an attractive appearance</p>
            </div>
          </div>
          <div className="fruit1">
            <div className="one">
              <img
                src="https://i.ibb.co/r5Lc7sh/Flame-Seedless.jpg"
                alt="Flame-Seedless"
                border="0"
              />
              <p>Flame-Seedless</p>
              <p>
                Experience the burst of flavor with Flame seedless grapes from
                India.
              </p>
            </div>
            <div className="one">
              <img
                src="https://i.ibb.co/74L3YgP/Sharad-Seedless.jpg"
                alt="Sharad-Seedless"
                border="0"
              />
              <p>Sharad-Seedless</p>
              <p>
                Savor the rich and intense taste of Sharad black seedless
                grapes.
              </p>
            </div>
            <div className="one">
              <img
                src="https://i.ibb.co/M714mKD/Thomson-Seedless.jpg"
                alt="Thompson-Seedless"
                border="0"
              />
              <p>Thompson-Seedless</p>
              <p>Sweet, juicy, and seedless are the perfect snack.</p>
            </div>
          </div>
        </div>
        <p style={{ margin: "5% 0% 5% 10%", textAlign: "left" }}>PACKAGING</p>
        <div className="package">
          <div className="fruit1">
            <img
              src="https://i.ibb.co/gy2GP9J/grapesmain3.jpg"
              alt="grapesmain3"
              border="0"
            />
          </div>
          <div className="fruit1">
            <img
              src="https://i.ibb.co/NY4g1Hv/grapesmain6.jpg"
              alt="grapesmain6"
              border="0"
            />
          </div>
        </div>
        <p style={{ margin: "5% 0% 5% 10%", textAlign: "left" }}>
          {" "}
          SEASONAL CHART
        </p>
        <div style={{ overflowX: "auto" }}>
          <table style={{ marginLeft: "5%" }}>
            <tbody>
              <tr>
                <th>Grapes Variety</th>
                <th>Color</th>
                <th>Jan</th>
                <th>Feb</th>
                <th>Mar</th>
                <th>Apr</th>
                <th>May</th>
                <th>Jun</th>
                <th>Jul</th>
                <th>Aug</th>
                <th>Sep</th>
                <th>Oct</th>
                <th>Nov</th>
                <th>Dec</th>
              </tr>
              <tr>
                <td>Thompson / White Seedless</td>
                <td>Amber</td>
                <td>
                  <img
                    src="https://i.ibb.co/T2rHc4N/tick1.jpg"
                    alt="tick1"
                    border="0"
                  />
                </td>
                <td>
                  <img
                    src="https://i.ibb.co/T2rHc4N/tick1.jpg"
                    alt="tick1"
                    border="0"
                  />
                </td>
                <td>
                  <img
                    src="https://i.ibb.co/T2rHc4N/tick1.jpg"
                    alt="tick1"
                    border="0"
                  />
                </td>
                <td>
                  <img
                    src="https://i.ibb.co/T2rHc4N/tick1.jpg"
                    alt="tick1"
                    border="0"
                  />
                </td>
                <td>
                  <img
                    src="https://i.ibb.co/T2rHc4N/tick1.jpg"
                    alt="tick1"
                    border="0"
                  />
                </td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
              </tr>
              <tr>
                <td>Sharad / Black Seedless</td>
                <td>Bluish Black</td>
                <td>
                  <img
                    src="https://i.ibb.co/Stm7Tsf/tick2.jpg"
                    alt="tick2"
                    border="0"
                  />
                </td>
                <td>
                  <img
                    src="https://i.ibb.co/Stm7Tsf/tick2.jpg"
                    alt="tick2"
                    border="0"
                  />
                </td>
                <td>
                  <img
                    src="https://i.ibb.co/Stm7Tsf/tick2.jpg"
                    alt="tick2"
                    border="0"
                  />
                </td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
              </tr>
              <tr>
                <td>Jumbo Seedless</td>
                <td>Bluish Black</td>
                <td>
                  <img
                    src="https://i.ibb.co/TRH4FR6/tick3.jpg"
                    alt="tick3"
                    border="0"
                  />
                </td>
                <td>
                  <img
                    src="https://i.ibb.co/TRH4FR6/tick3.jpg"
                    alt="tick3"
                    border="0"
                  />
                </td>
                <td>
                  <img
                    src="https://i.ibb.co/TRH4FR6/tick3.jpg"
                    alt="tick3"
                    border="0"
                  />
                </td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
              </tr>
              <tr>
                <td>Flame Seedless</td>
                <td>Dark Red</td>
                <td>
                  <img
                    src="https://i.ibb.co/VCLZ5x7/tick4.jpg"
                    alt="tick4"
                    border="0"
                  />
                </td>
                <td>
                  <img
                    src="https://i.ibb.co/VCLZ5x7/tick4.jpg"
                    alt="tick4"
                    border="0"
                  />
                </td>
                <td>
                  <img
                    src="https://i.ibb.co/VCLZ5x7/tick4.jpg"
                    alt="tick4"
                    border="0"
                  />
                </td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
              </tr>
              <tr>
                <td>Sonaka Seedless</td>
                <td>Yellowish</td>
                <td>
                  <img
                    src="https://i.ibb.co/0nqmh6m/tick5.jpg"
                    alt="tick5"
                    border="0"
                  />
                </td>
                <td>
                  <img
                    src="https://i.ibb.co/0nqmh6m/tick5.jpg"
                    alt="tick5"
                    border="0"
                  />
                </td>
                <td>
                  <img
                    src="https://i.ibb.co/0nqmh6m/tick5.jpg"
                    alt="tick5"
                    border="0"
                  />
                </td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="fruit-container">
          <div className="intro-text">
            <div className="fruit-section-bottom">
              {grapes.map((item) => (
                <div className="fruit-section-info" key={item.name}>
                  <img src={item.img} alt="" />
                  <h1 style={{ fontSize: "25px", fontWeight: "500" }}>
                    {item.name}
                  </h1>

                  <p>{item.text}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="see-more-content">
          <small>
            <u>slide here</u>
          </small>
          <KeyboardDoubleArrowRightIcon />
        </div>
        <hr
          style={{
            width: "100%",
            borderBottom: "2px solid #000",
            marginTop: "10%",
          }}
        ></hr>
        <div
          className="scroll"
          ref={pomegranateref}
          id="scrollpomegranate"
          style={{ position: "absolute", top: "40%" }}
        ></div>
        <div className="work-section-top">
          <h1 className="primary-heading" ref={h1Ref3}>
            Pomegranate
          </h1>
          <div className="work-video" ref={pomegranateref}>
            <video
              controls={false}
              width="100%"
              loop
              muted
              autoPlay
              playsInline
              style={{ pointerEvents: "none" }}
            >
              <source src={video3} type="video/mp4" />
              Sorry your browser not support video
            </video>
          </div>
          <p>
            Pomegranate is a fruit formed by the combination of several
            unit-like fruitlets. It is a round berry and has a thick skin on the
            outside and a lot of inner seeds surrounded by a juicy pulp. Its
            colour ranges from dark red to yellowish green. We supply fresh
            pomegranate on a year round basis through inclusive network of our
            associate farmers. Please get in touch with us for more details and
            offers
          </p>
        </div>

        <div
          className="fruit-images"
          style={{ display: "flex", justifyContent: "center", gap: "4rem" }}
        >
          <div className="fruit1">
            <div className="one ">
              <img
                src="https://i.ibb.co/1TTTjG6/ganesh-pomegranate-1557830044-4904571.jpg"
                alt="ganesh-pomegranate-1557830044-4904571"
                border="0"
              />
              <p>
                <b>Ganesh pomegranate</b>
              </p>
              <p>
                These are known for their deep red color, arils that are soft,
                and a balanced sweet-tart taste. They are mainly grown in
                Maharashtra and Gujarat.
              </p>
            </div>
            <div className="one">
              <img
                src="https://i.ibb.co/sg44cNg/ezgif-4-dd36c18c87.jpg"
                alt="ezgif-4-dd36c18c87"
                border="0"
              />
              <p>
                <b>Bhagwa pomegranate</b>
              </p>
              <p>
                {" "}
                Known for its dark red color, soft seeds, and sweet flavor,
                Bhagwa Pomegranates are grown mainly in Maharashtra and
                Karnataka
              </p>
            </div>
          </div>
        </div>
        <p style={{ margin: "5% 0% 5% 10%", textAlign: "left" }}>PACKAGING</p>
        <div className="package">
          <img
            src="https://i.ibb.co/hRJ8XNW/fresh-pomegranate-1565681237-5042174.jpg"
            alt="fresh-pomegranate-1565681237-5042174"
            border="0"
          />
        </div>
        <p style={{ margin: "5% 0% 5% 10%", textAlign: "left" }}>
          SEASONAL TABLE
        </p>
        <div style={{ overflowX: "auto" }}>
          <table style={{ marginLeft: "5%" }}>
            <tbody>
              <tr>
                <th>Fruit</th>
                <th>Jan</th>
                <th>Feb</th>
                <th>Mar</th>
                <th>Apr</th>
                <th>May</th>
                <th>Jun</th>
                <th>Jul</th>
                <th>Aug</th>
                <th>Sep</th>
                <th>Oct</th>
                <th>Nov</th>
                <th>Dec</th>
              </tr>
              <tr>
                <td>Pomegranate</td>
                <td>
                  <img
                    src="https://i.ibb.co/TBdBJ39/tick6.jpg"
                    alt="tick6"
                    border="0"
                  />
                </td>
                <td>
                  <img
                    src="https://i.ibb.co/TBdBJ39/tick6.jpg"
                    alt="tick6"
                    border="0"
                  />
                </td>
                <td>
                  <img
                    src="https://i.ibb.co/TBdBJ39/tick6.jpg"
                    alt="tick6"
                    border="0"
                  />
                </td>
                <td>
                  <img
                    src="https://i.ibb.co/TBdBJ39/tick6.jpg"
                    alt="tick6"
                    border="0"
                  />
                </td>
                <td>-</td>
                <td>-</td>
                <td>
                  <img
                    src="https://i.ibb.co/TBdBJ39/tick6.jpg"
                    alt="tick6"
                    border="0"
                  />
                </td>
                <td>
                  <img
                    src="https://i.ibb.co/TBdBJ39/tick6.jpg"
                    alt="tick6"
                    border="0"
                  />
                </td>
                <td>
                  <img
                    src="https://i.ibb.co/TBdBJ39/tick6.jpg"
                    alt="tick6"
                    border="0"
                  />
                </td>
                <td>
                  <img
                    src="https://i.ibb.co/TBdBJ39/tick6.jpg"
                    alt="tick6"
                    border="0"
                  />
                </td>
                <td>
                  <img
                    src="https://i.ibb.co/TBdBJ39/tick6.jpg"
                    alt="tick6"
                    border="0"
                  />
                </td>
                <td>
                  <img
                    src="https://i.ibb.co/TBdBJ39/tick6.jpg"
                    alt="tick6"
                    border="0"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="fruit-container">
          <div className="intro-text">
            <div className="fruit-section-bottom">
              {pomegranate.map((item) => (
                <div className="fruit-section-info" key={item.name}>
                  <h1 style={{ fontSize: "30px", fontWeight: "500" }}>
                    {item.name}
                  </h1>
                  <p style={{ fontWeight: "400" }}>{item.text}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="see-more-content">
          <small>
            <u>slide here</u>
          </small>
          <KeyboardDoubleArrowRightIcon />
        </div>
        <hr
          style={{
            width: "100%",
            borderBottom: "2px solid #000",
            marginTop: "10%",
          }}
        ></hr>
        <div
          className="scroll"
          ref={bananaref}
          id="scrollbanana"
          style={{ position: "absolute" }}
        ></div>

        <div className="work-section-top">
          <h1 className="primary-heading" ref={h1Ref4}>
            Banana
          </h1>
          <div className="work-video" ref={bananaref}>
            <video
              controls={false}
              width="100%"
              loop
              muted
              autoPlay
              playsInline
              style={{ pointerEvents: "none" }}
            >
              <source src={video4} type="video/mp4" />
              Sorry your browser not support video
            </video>
          </div>
          <p>
            Eos World from India is one of the largest producers and exporters
            of bananas in the world. With India's favorable climate and abundant
            production, India is a key player in the global banana export
            market.
          </p>
        </div>

        <div className="fruit-images">
          <div className="fruit1">
            <img
              src="https://i.ibb.co/hmCHjMq/banana2.jpg"
              alt="banana2"
              border="0"
            />
          </div>
        </div>
        <p style={{ margin: "5% 0% 5% 10%", textAlign: "left" }}>PACKAGING</p>
        <div className="package">
          <img
            src="https://i.ibb.co/L63qvd7/Large-Box-Bananas.jpg"
            alt="Large-Box-Bananas"
            border="0"
          />
        </div>
        <p style={{ margin: "5% 0% 5% 10%", textAlign: "left" }}>
          SEASONAL TABLE
        </p>
        <div style={{ overflowX: "auto" }}>
          <table style={{ marginLeft: "5%" }}>
            <tbody>
              <tr>
                <th>Fruit</th>
                <th>Jan</th>
                <th>Feb</th>
                <th>Mar</th>
                <th>Apr</th>
                <th>May</th>
                <th>Jun</th>
                <th>Jul</th>
                <th>Aug</th>
                <th>Sep</th>
                <th>Oct</th>
                <th>Nov</th>
                <th>Dec</th>
              </tr>
              <tr>
                <td>Banana</td>
                <td>🍌</td>
                <td>🍌</td>
                <td>🍌</td>
                <td>🍌</td>
                <td>🍌</td>
                <td>🍌</td>
                <td>🍌</td>
                <td>🍌</td>
                <td>🍌</td>
                <td>🍌</td>
                <td>🍌</td>
                <td>🍌</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="fruit-container">
          <div className="intro-text">
            <div className="fruit-section-bottom">
              {banana.map((item) => (
                <div className="fruit-section-info" key={item.name}>
                  <h1 style={{ fontSize: "30px", fontWeight: "500" }}>
                    {item.name}
                  </h1>
                  <p style={{ fontWeight: "400" }}>{item.text}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="see-more-content">
          <small>
            <u>slide here</u>
          </small>
          <KeyboardDoubleArrowRightIcon />
        </div>
        <hr
          style={{
            borderBottom: "2px solid #000",
            width: "100%",
            marginTop: "10%",
          }}
        />
        <div
          className="scroll"
          ref={strawberryref}
          id="scrollstrawberry"
          style={{ position: "absolute" }}
        ></div>

        <div className="work-section-top">
          <h1 className="primary-heading" ref={h1Ref5}>
            Strawberry
          </h1>
          <div className="work-video" ref={strawberryref}>
            <video
              controls={false}
              width="100%"
              loop
              muted
              autoPlay
              playsInline
              style={{ pointerEvents: "none" }}
            >
              <source src={video5} type="video/mp4" />
              Sorry your browser not support video
            </video>
          </div>
          <p>
            India is one of the major exporters of strawberries in the world.
            With the increasing demand for fresh and juicy strawberries, India
            has emerged as a key player in the global strawberry market.
          </p>
        </div>

        <div className="fruit-images">
          <div className="fruit1">
            <img
              src="https://i.ibb.co/N22XjLy/1432664914-strawberry-facts1.jpg"
              alt="1432664914-strawberry-facts1"
              border="0"
            />
          </div>
        </div>
        <p style={{ margin: "5% 0% 5% 10%", textAlign: "left" }}>PACKAGING</p>
        <div className="package">
          <img
            src="https://i.ibb.co/ykRGj4M/istockphoto-1196784002-612x612.jpg"
            alt="istockphoto-1196784002-612x612"
            border="0"
          />
        </div>
        <p style={{ margin: "5% 0% 5% 10%", textAlign: "left" }}>
          SEASONAL TABLE
        </p>
        <div style={{ overflowX: "auto" }}>
          <table style={{ marginLeft: "5%" }}>
            <tbody>
              <tr>
                <th>Fruit</th>
                <th>Jan</th>
                <th>Feb</th>
                <th>Mar</th>
                <th>Apr</th>
                <th>May</th>
                <th>Jun</th>
                <th>Jul</th>
                <th>Aug</th>
                <th>Sep</th>
                <th>Oct</th>
                <th>Nov</th>
                <th>Dec</th>
              </tr>
              <tr>
                <td>Strawberry</td>
                <td>🍓</td>
                <td>🍓</td>
                <td>🍓</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>🍓</td>
                <td>🍓</td>
                <td>🍓</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="fruit-container">
          <div className="intro-text">
            <div className="fruit-section-bottom">
              {strawberry.map((item) => (
                <div className="fruit-section-info" key={item.name}>
                  <h1 style={{ fontSize: "30px", fontWeight: "500" }}>
                    {item.name}
                  </h1>
                  <p style={{ fontWeight: "400" }}>{item.text}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="see-more-content">
          <small>
            <u>slide here</u>
          </small>
          <KeyboardDoubleArrowRightIcon />
        </div>
        <hr
          style={{
            borderBottom: "2px solid #000",
            width: "100%",
            marginTop: "10%",
          }}
        />
        <div
          className="scroll"
          ref={blueberryref}
          id="scrollblueberry"
          style={{ position: "absolute" }}
        ></div>

        <div className="work-section-top">
          <h1 className="primary-heading" ref={h1Ref6}>
            Blueberry
          </h1>
          <div className="work-video" ref={blueberryref}>
            <video
              controls={false}
              width="100%"
              loop
              muted
              autoPlay
              playsInline
              style={{ pointerEvents: "none" }}
            >
              <source src={video6} type="video/mp4" />
              Sorry your browser not support video
            </video>
          </div>
          <p>
            Eos World is emerging as a major exporter of blueberries in the
            global market. With its favorable climate and increasing production,
            India is poised to become a significant player in the blueberry
            export market.
          </p>
        </div>

        <div className="fruit-images">
          <div className="fruit1">
            <img
              src="https://i.ibb.co/021D7X9/photo-1498557850523-fd3d118b962e.jpg"
              alt="photo"
              border="0"
            />
          </div>
        </div>
        <p style={{ margin: "5% 0% 5% 10%" }}>PACKAGING</p>
        <div className="package">
          <img
            src="https://i.ibb.co/56LnsMB/5963530-022420-kgo-berry-packaging-img-Image-06-24-06-24.jpg"
            alt="5963530-022420-kgo-berry-packaging-img-Image-06-24-06-24"
            border="0"
          />
        </div>
        <p style={{ margin: "5% 0% 5% 10%", textAlign: "left" }}>
          SEASONAL TABLE
        </p>
        <div style={{ overflowX: "auto" }}>
          <table style={{ marginLeft: "5%" }}>
            <tbody>
              <tr>
                <th>Fruit</th>
                <th>Jan</th>
                <th>Feb</th>
                <th>Mar</th>
                <th>Apr</th>
                <th>May</th>
                <th>Jun</th>
                <th>Jul</th>
                <th>Aug</th>
                <th>Sep</th>
                <th>Oct</th>
                <th>Nov</th>
                <th>Dec</th>
              </tr>
              <tr>
                <td>Blueberries</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>
                  <img
                    src="https://i.ibb.co/f0HTpFZ/image.png"
                    alt="image"
                    border="0"
                  />
                </td>
                <td>
                  <img
                    src="https://i.ibb.co/f0HTpFZ/image.png"
                    alt="image"
                    border="0"
                  />
                </td>
                <td>
                  <img
                    src="https://i.ibb.co/f0HTpFZ/image.png"
                    alt="image"
                    border="0"
                  />
                </td>
                <td>
                  <img
                    src="https://i.ibb.co/f0HTpFZ/image.png"
                    alt="image"
                    border="0"
                  />
                </td>
                <td>
                  <img
                    src="https://i.ibb.co/f0HTpFZ/image.png"
                    alt="image"
                    border="0"
                  />
                </td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="fruit-container">
          <div className="intro-text">
            <div className="fruit-section-bottom">
              {blue.map((item) => (
                <div className="fruit-section-info" key={item.name}>
                  <h1 style={{ fontSize: "30px", fontWeight: "500" }}>
                    {item.name}
                  </h1>
                  <p style={{ fontWeight: "400" }}>{item.text}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="see-more-content">
          <small>
            <u>slide here</u>
          </small>
          <KeyboardDoubleArrowRightIcon />
        </div>
      </div>
    </div>
  );
}

export default Fruits;

import React, { useEffect } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Link } from "react-router-dom";
import { BsFillPlayCircleFill } from "react-icons/bs";
function More() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="director">
        <br></br>
        <h1>Our Leadership</h1>
      </div>
      <br></br>
      <br></br>

      <div className="carousel-director">
        <img src="https://i.ibb.co/F0Hvb05/I-3.png" alt="I-3" border="0" />
        <div className="myCarousel">
          <h3>Saurabh Pawar</h3>
          <h4>Director</h4>
          <p>
            We are grateful to have a director that adds a wealth of knowledge
            and skills to our food export company. With a Bachelor's degree in
            Business Administration and a specialisation in marketing from a
            renowned university, our director is well-equipped to lead our team
            and keep our company at the forefront of the industry.
            <br></br>
            Our director has vast experience in the food export market and has
            worked with some of the most reputable organisations in the field.
            He understands the problems and opportunities that our sector faces
            and is committed to staying ahead of the curve.
            <br></br>
            Our director is dedicated to ensuring that our products are not only
            of the greatest quality but are also efficiently promoted to our
            clients all over the world. She has a passion for marketing and a
            keen eye for detail. He believes that successful marketing is
            critical to the success of any organisation and collaborates closely
            with our team to design unique marketing strategies that match the
            needs of our clients.
            <br></br>
            Our director is also dedicated to continued education and
            professional growth. He attends industry conferences and seminars on
            a monthly basis to stay current on the newest trends and advances in
            the food export sector. This commitment to continuous learning
            guarantees that our company remains at the forefront of the industry
            and is able to give the best products and services to our consumers.
            <br></br>
            We are proud to have such an experienced and dedicated director
            leading our team. His knowledge, expertise, and passion for the food
            export industry are instrumental in our continued success.
          </p>
        </div>
      </div>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <div className="carousel-director">
        <img
          src="https://i.ibb.co/drgmWJ8/Screenshot-2023-04-29-113114.png"
          alt="Screenshot-2023-04-29-113114"
          border="0"
        />
        <div className="myCarousel">
          <h3>Sandeep Nikam</h3>
          <h4>Director</h4>
          <p>
            Our team is lucky to be led by a director with vast experience in
            the food, paper, and packaging industries. Our director brings years
            of experience in various sectors to our organisation, as well as a
            wealth of knowledge and expertise.
            <br></br>
            Our director has a thorough awareness of the distinct difficulties
            and opportunities given by each of these businesses. He has worked
            with some of the most prestigious companies in each industry and has
            a demonstrated track record of success.
            <br></br>
            With a sharp eye for detail and a commitment to perfection, our
            director is committed to keeping our company at the forefront of the
            food, paper, and packaging industries. He understands the importance
            of quality and safety in these industries and collaborates closely
            with our team to guarantee that our goods exceed the highest
            standards.
            <br></br>
            Our director is also committed to sustainability and ethical
            business practises. He believes that it is our job to minimise our
            environmental impact and to support the communities in which we
            operate. He collaborates closely with our suppliers and partners to
            ensure that we get our resources and goods in a responsible and
            sustainable manner.
            <br></br>
            In addition to his experience in these industries, our director is
            also a skilled leader and team builder. He understands the
            importance of teamwork and collaboration and works closely with our
            team to ensure that we are all working towards a common goal.
            <br></br>
            We are pleased to have such an experienced and devoted director
            managing our staff. His knowledge, expertise, and dedication to
            excellence are critical to our continued success in the food, paper,
            and packaging industries.
          </p>
        </div>
      </div>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <div className="carousel-director">
        <img
          src="https://i.ibb.co/r0FPsBh/IMG-0859.jpg"
          alt="IMG-0859"
          border="0"
        />
        <div className="myCarousel">
          <h3>Siddharth Pawar</h3>
          <h4>Director</h4>
          <p>
            We are glad to have a director with substantial food business
            knowledge as well as an engineering background. Our director adds a
            distinct perspective to our company so is critical in ensuring that
            we remain at the forefront of the industry.
            <br></br>
            With a degree in engineering, our director has a solid basis in the
            technical aspects of food production and processing. He knows the
            importance of quality and safety in the food industry and works
            closely with our staff to guarantee that our goods exceed the
            highest standards.
            <br></br>
            Our director has years of experience working in the food industry in
            addition to his technical abilities. He has worked with some of the
            most reputable companies in the industry and has a thorough
            understanding of the difficulties and opportunities that our sector
            faces.
            <br></br>
            Our director is likewise committed to innovation, and he is
            continuously looking for new technologies and methods to better our
            products and operations. He knows the necessity of staying ahead of
            the curve in the continually changing food business and is committed
            to keeping our company at the forefront of innovation.
            <br></br>
            Our director is devoted to building a culture of collaboration and
            teamwork inside our organisation as a talented leader and team
            builder. He knows that our success is a team effort and works
            closely with our team to ensure that we are all working towards the
            same objective.
            <br></br>
            We are grateful to have such an experienced and devoted director
            directing our staff. His experience, skills, and commitment to
            quality play an important role in our sustained success in the food
            sector.
          </p>
        </div>
      </div>
      <div
        className="primary-text"
        style={{
          paddingTop: "8%",
          margin: "auto",
          textAlign: "left",
          maxWidth: "1000px",
          padding: "8% 2% 2% 2%",
        }}
      >
        <p className="primary-subheading">Mission</p>
        Our objective is to supply high-quality, safe food to clients all around
        the world. We are devoted to obtaining our products from sustainable and
        ethical sources and ensuring that they satisfy the highest quality and
        safety standards.
        <br></br>
        <br></br>
        <p className="primary-subheading">Vision</p>
        Our vision is to be a leading global exporter of high-quality and safe
        food products. We strive to be recognized for our commitment to quality,
        sustainability, and innovation in the food industry.
        <br></br>
        <br></br>
        <p className="primary-subheading">Values</p>
        <b className="color-more">Quality</b> : We are committed to providing
        our customers with the highest-quality food products that meet the
        highest standards of safety and nutrition.
        <br></br>
        <br></br>
        <b className="color-more">Sustainability</b> : We believe that it is our
        responsibility to minimize our impact on the environment and to support
        sustainable and ethical sourcing practices.
        <br></br>
        <br></br>
        <b className="color-more">Innovation</b> : We are constantly exploring
        new technologies and methods to improve our products and processes and
        remain at the forefront of the industry.
        <br></br>
        <br></br>
        <b className="color-more">Integrity</b> : We conduct our business with
        honesty, transparency, and ethical practices, building trust and respect
        with our customers, suppliers, and partners.
        <br></br>
        <br></br>
        <b className="color-more">Customer satisfaction</b> : We prioritise our
        clients' pleasure and needs by providing great products and services
        that meet and surpass their expectations.
        <br></br>
        <br></br>
        <b className="color-more">Teamwork</b> : We value collaboration,
        communication, and teamwork, working together to achieve our goals and
        promote the success of our customers and partners.
        <br></br>
        <br></br>
        These values guide our decision-making and actions as we work to achieve
        our mission and vision as a global food exporter.
      </div>
      <div className="button-watch-video">
        <button className="watch-video-button">
          <Link
            to="https://drive.google.com/file/d/1UZeN-1NB6wBUhh9yFSUwEVyPL7ZKgeMW/preview"
            style={{
              textDecoration: "none",
              justifyContent: "center",
              display: "flex",
            }}
          >
            <BsFillPlayCircleFill /> Watch Video
          </Link>
        </button>
      </div>
    </div>
  );
}

export default More;

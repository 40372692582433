import React, { useEffect } from "react";
import video5 from "../Assets/corn.mp4";
import video6 from "../Assets/peas.mp4";

function Vegetables_Product() {
  useEffect(() => {
    window.scroll(0, 0);
  });
  return (
    <div>
      <p className="primary-subheading">
        <img
          src="https://i.ibb.co/Tqfhn1p/healthy-veggies-written-vegetables-metaphor-260nw-562721062.jpg"
          alt=""
        />
      </p>
      <h1
        className="primary-heading"
        style={{ color: "#fe9e0d", paddingTop: "5%", margin: "auto" }}
      >
        {" "}
      </h1>
      <div className="work-section-top">
        <h1 className="primary-heading">Corn</h1>
        <p>
          Fiber keeps you healthy and fresh. Eos World Sweet corn is the most
          famous vegetable for its Sweetness. Steamed corn is rich source of
          Fiber a necessary food requitement. Sweet Corn can be used for cup
          corn ,Tawa Fry vegetable ,Corn Tikki , Corn atti, Corn Salad, corn
          samosa, Add a new fiber to you food
        </p>
      </div>
      <div className="work-video">
        <video
          controls={false}
          width="100%"
          loop
          muted
          autoPlay
          playsInline
          style={{ pointerEvents: "none" }}
        >
          <source src={video5} type="video/mp4" />
          Sorry your browser not support video
        </video>
      </div>

      <div className="fruit-images">
        <div className="fruit1">
          <img
            src="https://i.ibb.co/Fst9YGs/is-corn-grain-1544222258.jpg"
            alt="is-corn-grain-1544222258"
            border="0"
          />
        </div>
      </div>
      <p style={{ marginLeft: "5%", textAlign: "left" }}>SEASONAL TABLE</p>
      <div style={{ overflowX: "auto" }}>
        <table style={{ marginLeft: "5%" }}>
          <tbody>
            <tr>
              <th>Vegiee</th>
              <th>Jan</th>
              <th>Feb</th>
              <th>Mar</th>
              <th>Apr</th>
              <th>May</th>
              <th>Jun</th>
              <th>Jul</th>
              <th>Aug</th>
              <th>Sep</th>
              <th>Oct</th>
              <th>Nov</th>
              <th>Dec</th>
            </tr>
            <tr>
              <td>Corn</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>🌽</td>
              <td>🌽</td>
              <td>🌽</td>
              <td>🌽</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
            </tr>
          </tbody>
        </table>
      </div>
      <hr
        style={{
          borderBottom: "2px solid #000",
          width: "100%",
          marginTop: "10%",
          marginBottom: "10%",
        }}
      />
      <div className="work-section-top">
        <p className="primary-subheading"></p>
        <h1 className="primary-heading">Peas</h1>
        <p className="primary-text">
          A pea is a most commonly green, occasionally golden yellow, or
          infrequently purple pod-shaped vegetable, widely grown as a
          cool-season vegetable crop. The seeds may be planted as soon as the
          soil temperature reaches 10 °C
        </p>
      </div>
      <div className="work-video">
        <video
          controls={false}
          width="100%"
          loop
          muted
          autoPlay
          playsInline
          style={{ pointerEvents: "none" }}
        >
          <source src={video6} type="video/mp4" />
          Sorry your browser not support video
        </video>
      </div>
    </div>
  );
}

export default Vegetables_Product;

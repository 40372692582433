import React, { useEffect } from "react";
import SliderProducts from "../Components/SliderProducts";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";

function Spices(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();
  const navigateHome = () => {
    let path = `${props.link}`;
    navigate(path);
  };
  return (
    <div>
      <div className="work-section-wrapper">
        <div className="work-section-top">
          <p className="primary-subheading">
            <img src={props.img} alt="" />
          </p>
          <h1 className="primary-heading"> </h1>
          <SliderProducts />
          <p className="work-spices-text">{props.content}</p>
        </div>

        <div className="spices-button">
          <Stack spacing={2} direction="row">
            <Button variant="contained" onClick={navigateHome}>
              Get Details
            </Button>
          </Stack>
        </div>
      </div>
    </div>
  );
}

export default Spices;

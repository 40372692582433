import React, { useEffect, useState } from "react";
import SliderContent from "./SliderProductContent";
import SliderProductImage from "./VegetableSliderProductImage";
import "./SliderProduct.css";

function SliderProducts() {
  const len = SliderProductImage.length - 1;
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex(activeIndex === len ? 0 : activeIndex + 1);
    }, 3000);
    return () => clearInterval(interval);
  }, [activeIndex, len]);

  return (
    <div className="slider-product-container">
      <div className="product-main">
        <SliderContent
          activeIndex={activeIndex}
          sliderImage={SliderProductImage}
        />
      </div>
    </div>
  );
}

export default SliderProducts;

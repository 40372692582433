import React, { useEffect } from "react";
import video1 from "../Assets/foxnut.mp4";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
function SuperFood_Products() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const foxnut = [
    {
      name: "Intro",
      text: "Foxnuts, also known as Makhana or Lotus seeds, are the edible seeds of the lotus plant. They are native to Asia, specifically India, China,Japan, and Korea.",
    },
    {
      name: "Consumption",
      text: "Foxnuts have numerous health benefits and unique taste.India is the largest producer and exporter of Foxnuts in the world, accounting for more than 90% of the total production. ",
    },
    {
      name: "Packaging",
      text: "Eos World Foxnuts are packed with nutrients and are a great source of protein,Eos World Foxnuts are available in various packaging options, including vacuum-sealed packs, tin cans, and jars.",
    },
    {
      name: "Benefits",
      text: "Foxnuts have several health benefits,including improving heart health, aiding digestion, and reducing inflammation. fiber, and minerals like magnesium, potassium, and phosphorus. They are also low in calories and fat.",
    },
  ];
  return (
    <div>
      <div className="work-section-top">
        <img
          src="https://i.ibb.co/D9XgJgn/superfood-text-word-green-love-260nw-1406613272.jpg"
          alt=""
          className="primary-subheading"
        />

        <h1 className="primary-heading">Foxnut</h1>
        <div className="work-video">
          <video
            controls={false}
            width="100%"
            loop
            muted
            autoPlay
            playsInline
            style={{ pointerEvents: "none" }}
          >
            <source src={video1} type="video/mp4" />
            Sorry your browser not support video
          </video>
        </div>
        <div className="fruit-images">
          <div className="fruit1">
            <div className="one">
              <img
                src="https://i0.wp.com/post.healthline.com/wp-content/uploads/2021/05/makhana-1296x728-header.jpg"
                alt=""
              />
            </div>
            <div className="one">
              {" "}
              <img
                src="https://www.whiskaffair.com/wp-content/uploads/2017/12/Makhana-Namkeen-2-3.jpg"
                alt=""
              />
            </div>
          </div>
        </div>

        <p className="primary-text">
          It is a type of seed derived from the Euryale ferox plant. They're
          also sometimes referred to as fox nuts or lotus seeds. Makhanas are
          widely cultivated throughout Asia and often used in traditional forms
          of medicine to treat various conditions
        </p>
      </div>

      <div className="fruit-container">
        <div className="intro-text">
          <div className="fruit-section-bottom">
            {foxnut.map((item) => (
              <div className="fruit-section-info" key={item.name}>
                <h1 style={{ fontSize: "1.5rem", fontWeight: "500" }}>
                  {item.name}
                </h1>
                <p style={{ fontWeight: "400" }}>{item.text}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="see-more-content">
        <small>
          <u>slide here</u>
        </small>
        <KeyboardDoubleArrowRightIcon />
      </div>
    </div>
  );
}

export default SuperFood_Products;
